import { TabContext } from "@mui/lab";
import {
  Box,
  Card,
  CardContent,
  Chip,
  List,
  ListItem,
  Tab,
} from "@mui/material";
import { CustomTabList, CustomTabPanel } from "components/TabList";
import { useState } from "react";
import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";
import { AppUtils } from "utils/utils";
import HomePageV3_WorkingHours from "./working_hours";

export default function HomePageV3_Events() {
  const [activeTab, setActiveTab] = useState("Etkinlikler");

  const handleChange = (_, newTab) => setActiveTab(newTab);

  const TABS = [
    {
      value: "Etkinlikler",
      component: <Events />,
    },
    {
      value: "Mesailer",
      component: <HomePageV3_WorkingHours />,
    },
  ];

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent className="flex flex-col overflow-auto h-full">
        <Box sx={{ width: "100%" }}>
          <TabContext value={activeTab}>
            <CustomTabList onChange={handleChange}>
              {TABS.map((tab) => (
                <Tab key={tab.value} label={tab.value} value={tab.value} />
              ))}
            </CustomTabList>

            {TABS.map((tab) => (
              <CustomTabPanel key={tab.value} value={tab.value}>
                {tab.component}
              </CustomTabPanel>
            ))}
          </TabContext>
        </Box>
      </CardContent>
    </Card>
  );
}
function Events() {
  const {
    data: events,
    isLoading,
    error,
  } = useFetchData(ApiConstants.getEvents);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error...</div>;
  return (
    <Box sx={{ height: "100%", overflow: "auto" }}>
      <List className="space-y-0" dense={true} disablePadding={true}>
        {events?.map((v) => (
          <ListItem
            key={v._id}
            className="py-1 flex space-x-1"
            dense={true}
            disablePadding={true}
          >
            <Chip
              label={
                <span className="text-[.7rem]">
                  {AppUtils.formatClock(v.createdAt)}
                </span>
              }
              size="small"
              variant="outlined"
              color="primary"
              sx={{ borderRadius: "0.25rem" }}
            />
            <span className="text-[.7rem] text-gray-500">{v.value}</span>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
