import HomeActiveWorks from "./active_works";
import HomeEvents from "./events";
import HomeLastProject from "./last_projects";
import HomeMap from "./map";
import HomePersonelHeader from "./personel_header";
import HomeWorkHours from "./workHours";
export default function HomePageV1() {
  return (
    <div className="flex p-4 relative gap-x-2  h-[84vh]">
      <div className="w-full flex flex-col gap-y-2 h-[50%]">
        <div className="w-full flex pb-4 h-96 gap-x-2">
          <HomeMap />
          <HomeWorkHours />
        </div>
        <div className="w-full flex  h-full gap-x-2 ">
          <HomeLastProject />
          <HomeActiveWorks />
        </div>
      </div>
      <div className="flex w-5/12 flex-col gap-y-3  ">
        <HomePersonelHeader />
        <HomeEvents />
      </div>
    </div>
  );
}
