import { Icon } from "@iconify/react";
import { TabContext } from "@mui/lab";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { CustomTabList, CustomTabPanel } from "components/TabList";
import { useState } from "react";
import HomePage_Users from "./users";
import HomePageV3 from "./v3";
import HomePageV1 from "./v1";

export default function HomePage() {
  const [activeTab, setActiveTab] = useState("v3");

  const handleChange = (_, newTab) => setActiveTab(newTab);

  const TABS = [
    {
      label: (
        <div className="flex items-center space-x-2">
          <span className="">Anasayfa</span>
          <Icon icon="mdi:new-box" className="text-red-500 text-lg  " />
        </div>
      ),
      value: "v3",
      component: <HomePageV3 />,
    },
    // {
    //   label: (
    //     <div className="flex items-center space-x-2">
    //       <span className="">Yeni</span>
    //       <Icon icon="mdi:new-box" className="text-red-500 text-lg  " />
    //     </div>
    //   ),
    //   value: "homeV2",
    //   component: <HomePageV2 />,
    // },
    {
      label: "Eski",
      value: "homeV1",
      component: <HomePageV1 />,
    },
    {
      label: "Personeller",
      value: "users",
      component: <HomePage_Users />,
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <TabContext value={activeTab}>
        <CustomTabList onChange={handleChange}>
          {TABS.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </CustomTabList>

        {TABS.map((tab) => (
          <CustomTabPanel key={tab.value} value={tab.value}>
            {tab.component}
          </CustomTabPanel>
        ))}
      </TabContext>
    </Box>
  );
}
