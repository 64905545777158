import WorkReportsTable from "pages/reports/tables/work_table";
import { useEffect, useState } from "react";
import WorkService from "services/works_service";

function ProcessMiningWorksDialog({ edgeData }) {
  const [works, setWorks] = useState(undefined);

  useEffect(() => {
    const fetchData = async () => {
      const panelIDs = (edgeData.works ?? []).map((data) => data._id);
      const query = `?panels=${panelIDs.join(",")}`;

      try {
        console.log("Fetched :");
        const works = await WorkService.getAll({ query });
        console.log("Fetched works:", works);
        setWorks(works);
      } catch (error) {
        console.error("Error fetching works:", error);
        setWorks([]); // Hata durumunda boş dizi ata
      }
    };

    fetchData();
  }, [edgeData]);
  console.log("===================================="); 
  if (works === undefined) {
    return <>Loading...</>;
  }

  return <WorkReportsTable worksData={works}></WorkReportsTable>;
}

export default ProcessMiningWorksDialog;
