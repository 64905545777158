import Loading from "components/Loading";
import CustomLottie from "components/Lottie";
import Table from "components/Table";
import DateColumnFilter from "components/Table/Filter/DateFilter";
import { NumberRangeColumnFilter } from "components/Table/Filter/NumberRange";
import { OverflowCell } from "components/Table/components/overflow";
import { CustomListBoxWithoutFormik } from "components/listbox";
import { Checkbox } from "primereact/checkbox";
import { useEffect, useMemo, useState } from "react";
import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";
import noData from "../../../asset/lottie/table-no-data";

import { DateColumn } from "components/Table/components/date";
import WorkTableDatePicker from "../component/date_picker";

export default function WorkReportsTable({ worksData }) {
  const [data, setData] = useState([]);
  const [isDaily, setIsDaily] = useState(false);
  const [isDevelop, setIsDevelop] = useState(false);
  const [panel, setPanel] = useState(null);
  const [calendarYear, setCalendarYear] = useState(
    new Date().getFullYear().toString()
  );
  const [calendarMonth, setCalendarMonth] = useState(new Date().getMonth());

  const monthMapping = useMemo(
    () => ({
      Tümü: null,
      Ocak: 0,
      Şubat: 1,
      Mart: 2,
      Nisan: 3,
      Mayıs: 4,
      Haziran: 5,
      Temmuz: 6,
      Ağustos: 7,
      Eylül: 8,
      Ekim: 9,
      Kasım: 10,
      Aralık: 11,
    }),
    []
  );

  const yearsList = useMemo(() => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: currentYear - 2021 }, (_, i) =>
      (currentYear - i).toString()
    ).concat("Tümü");
  }, []);

  const { data: panels } = useFetchData(ApiConstants.PANELS);

  const {
    data: worksFromDb,
    isLoading,
    error,
  } = useFetchData(
    `${ApiConstants.WORKS}${
      calendarYear !== "Tümü"
        ? `?year=${calendarYear}&panels=${
            panel?._id ?? ""
          }&month=${calendarMonth}`
        : `?panels=${panel?._id ?? ""}`
    }`
  );

  useEffect(() => {
    if (worksFromDb) {
      const filteredData = worksFromDb
        .filter((v) => v.endedDate !== null)
        .map((v) => ({
          ...v,
          startedDateOnlyDate: v.startedDate.slice(0, 10),
          endedDateOnlyDate: v.endedDate.slice(0, 10),
        }));
      setData(filteredData);
    }
  }, [worksFromDb]);

  const DateColumnCell = ({ value, column, row }) => {
    return isDevelop ? (
      <div>{WorkTableDatePicker({ value, column, row })}</div>
    ) : (
      DateColumn({ value, column, row })
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Personel",
        accessor: "user",
        aggregate: "uniqueCount",
        Aggregated: ({ value }) => `${value} Personel`,
      },
      {
        Header: "Bölüm",
        accessor: "divisionName",
        aggregate: "uniqueCount",
        Aggregated: ({ value }) => `${value} Bölüm`,
      },
      {
        Header: "Proje",
        accessor: "projectName",
        aggregate: "uniqueCount",
        Aggregated: ({ value }) => `${value} Proje`,
      },
      {
        Header: "Pano Adı",
        accessor: "panelName",
        Cell: OverflowCell,
        aggregate: "uniqueCount",
        Aggregated: ({ value }) => `${value} Pano`,
      },
      {
        Header: "Pano Kodu",
        accessor: "panelCode",
        Cell: OverflowCell,
        aggregate: "uniqueCount",
        Aggregated: ({ value }) => `${value} Pano`,
      },
      {
        Header: "Başlangıç",
        accessor: isDaily ? "startedDateOnlyDate" : "startedDate",
        dateFormat: isDaily ? "DD-MM-YYYY" : "DD-MM-YYYY HH:mm",
        Cell: DateColumnCell,
        Filter: DateColumnFilter,
        filter: "fromDate",
        type: "date",
      },
      {
        Header: "Bitiş",
        accessor: isDaily ? "endedDateOnlyDate" : "endedDate",
        dateFormat: isDaily ? "DD-MM-YYYY" : "DD-MM-YYYY HH:mm",
        Cell: DateColumnCell,
        Filter: DateColumnFilter,
        filter: "toDate",
        type: "date",
      },
      {
        Header: "Toplam Süre (dk)",
        accessor: "usedTime",
        Filter: NumberRangeColumnFilter,
        filter: "between",
        aggregate: "sum",
        Aggregated: ({ value }) => `${value} dk`,
      },
      {
        Header: "Bütçe (₺)",
        accessor: "usedBudget",
        Filter: NumberRangeColumnFilter,
        filter: "between",
        aggregate: "sum",
        Aggregated: ({ value }) => `${value} ₺`,
      },
    ],
    [isDaily]
  );

  if (isLoading) return <Loading />;
  if (error) return <div>Veri yüklenirken hata oluştu: {error.message}</div>;

  return (
    <>
      <div className="flex justify-end mb-3 gap-2">
        {panels && (
          <CustomListBoxWithoutFormik
            label="Pano"
            selected={panel?.panelCode ?? "Tümü"}
            setSelected={(selectedPanelCode) => {
              if (selectedPanelCode === "Tümü") {
                setPanel(null);
                return;
              }
              const selectedPanel = Object.values(panels).find(
                (p) => p.panelCode === selectedPanelCode
              );
              setPanel(selectedPanel);
            }}
            values={["Tümü", ...Object.values(panels).map((p) => p.panelCode)]}
          />
        )}
        {calendarYear !== "Tümü" && (
          <CustomListBoxWithoutFormik
            label="Ay"
            selected={Object.keys(monthMapping).find(
              (key) => monthMapping[key] === calendarMonth
            )}
            setSelected={(month) => setCalendarMonth(monthMapping[month])}
            values={Object.keys(monthMapping)}
          />
        )}
        <CustomListBoxWithoutFormik
          label="Yıl"
          selected={calendarYear}
          setSelected={setCalendarYear}
          values={yearsList}
        />
      </div>

      {data.length === 0 ? (
        <div className="flex flex-col items-center justify-center text-gray-500 pt-20">
          <CustomLottie animationData={noData} />
          <p>Kayıt bulunamadı...</p>
        </div>
      ) : (
        <Table
          className="h-full"
          columns={columns}
          data={data}
          exportExcel
          pageSize={20}
          fileName="İş_Kayıtları"
          Header={
            <div className="flex w-full justify-end gap-4 text-xs text-slate-600 font-medium">
              <Checkbox
                onChange={(e) => setIsDaily(e.checked)}
                checked={isDaily}
              />{" "}
              Günlük Veriler
              <Checkbox
                onChange={(e) => setIsDevelop(e.checked)}
                checked={isDevelop}
              />{" "}
              Gelişmiş Seçenekler
            </div>
          }
        />
      )}
    </>
  );
}
