import { Card, CardContent, Grid } from "@mui/material";
import { ResponsiveCalendar } from "@nivo/calendar";
import CustomDialog from "components/dialog";
import PreviewImage from "components/PreviewImage";
import WorkReportsTable from "pages/reports/tables/work_table";
import { useEffect, useState } from "react";
import ReactApexcharts from "react-apexcharts";
import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";
import { AppUtils } from "utils/utils";

export default function HomePage_Users() {
  const [data, setData] = useState(null);

  const [calendarDate, setCalendarDate] = useState(
    new Date().getFullYear().toString()
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [averageTime, setAverageTime] = useState(null);

  const [isOpenWorksDialog, setIsOpenWorksDialog] = useState(false);
  const [selectedWorks, setSelectedWorks] = useState(null);

  const { data: works } = useFetchData(
    `${ApiConstants.WORKS}${`?year=${calendarDate}`}`
  );

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm === "") {
        setData(null);
        dataModified(works);
        return;
      }
      const filteredWorks = works.filter((a) =>
        a.user.toLowerCase().includes(searchTerm.toLowerCase())
      );
      dataModified(filteredWorks);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {}, [averageTime]);

  useEffect(() => {
    if (works) {
      dataModified(works);
    }
  }, [calendarDate, works]);

  useEffect(() => {}, [averageTime]);

  const dataModified = (pWorks) => {
    var completedWorks = getCompletedWorks(pWorks);
    var dataGroupByUser = AppUtils.groupBy(completedWorks, "userID");
    setData(dataGroupByUser);
  };

  const userRadarData = (data) => {
    var _data = AppUtils.groupBy(data, "divisionName");
    const labelData = [];
    const rawSeries = _data.map((v) => {
      var _totalUsedTime = v.value
        .reduce((partialSum, a) => partialSum + a.usedTime, 0)
        .toFixed(0);
      labelData.push(`${v.key}`);
      return _totalUsedTime;
    });

    return { labels: labelData, series: [{ name: "Bölüm", data: rawSeries }] };
  };

  const getUserCalendarData = (works) => {
    var _calendarData = AppUtils.groupBy(works, "startedDateOnlyDate");
    _calendarData = _calendarData.map((v) => {
      var _totalUsedTime = v.value
        .reduce((partialSum, a) => partialSum + a.usedTime, 0)
        .toFixed(0);
      return {
        value: _totalUsedTime,
        day: v.key,
      };
    });
    return _calendarData;
  };
  const getCompletedWorks = (pWorks) => {
    const _works = pWorks?.filter((a) => a.endedDate !== null);
    return _works.map((v) => {
      const startedDateOnlyDate = v.startedDate.slice(0, 10);
      const endedDateOnlyDate = v.endedDate.slice(0, 10);
      return { ...v, startedDateOnlyDate, endedDateOnlyDate };
    });
  };

  if (!data) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <CustomDialog
        isOpen={isOpenWorksDialog}
        setIsOpen={setIsOpenWorksDialog}
        title={""}
        maxWidth=" max-w-7xl"
        body={<WorkReportsTable worksData={selectedWorks}></WorkReportsTable>}
      ></CustomDialog>
      <Grid container xs={12} spacing={1} className="relative ">
        <Grid
          item
          xs={12}
          className="flex items-center justify-between w-full px-1 sticky top-0 z-[2]"
        >
          <Card className="w-full">
            <CardContent className="flex gap-2 ">
              <select
                className="flex border-0 bg-[#f5f5f5] rounded-md text-sm  appearance-none"
                onChange={(e) => setCalendarDate(e.target.value)}
                value={calendarDate}
              >
                {["2022", "2023", "2024", "2025", "2026", "2027"].map((v) => (
                  <option key={v} value={v}>
                    {v}
                  </option>
                ))}
              </select>
              <input
                type="text"
                placeholder="Personel adı"
                className="border-0 bg-[#f5f5f5] rounded-md text-sm  appearance-none"
                onChange={(e) => {
                  const value = e.target.value;
                  setSearchTerm(value);
                }}
              />
              <input
                type="number"
                placeholder="Ortalama Mesai Saati"
                className="border-0 bg-[#f5f5f5] rounded-md text-sm  appearance-none"
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === "" || value === null || value === "0") {
                    setAverageTime(null);
                    return;
                  }
                  setAverageTime(value);
                }}
              />
            </CardContent>
          </Card>
        </Grid>
        {data &&
          data.map((item, k) => {
            var totalUsedTime = item.value
              .reduce((partialSum, a) => partialSum + a.usedTime, 0)
              .toFixed(0);
            const hours = Math.floor(totalUsedTime / 60); // Dakikayı saate çevir
            const remainingMinutes = totalUsedTime % 60; // Kalan dakikayı hesapla

            return (
              <Grid item xs={12} key={item.key}>
                <Card className="w-full">
                  <CardContent className="p-0">
                    <div className="flex items-start justify-start flex-col gap-2">
                      <div className="flex items-center gap-2">
                        <PreviewImage
                          src={item.value[0].userProfile}
                          className={"w-12 h-12 rounded-full"}
                        />
                        <div>{`#${k + 1} ${item.value[0].user}`}</div>
                        <div className="bg-gray-100 p-2 text-xs rounded-md">
                          {`Toplam çalışma süresi : ${hours} saat ${remainingMinutes} dakika`}
                        </div>
                        <div className="bg-gray-100 p-2 text-xs rounded-md">
                          {`Ortalama çalışma süresi : ${(
                            totalUsedTime /
                            getUserCalendarData(item.value).length
                          ).toFixed(0)} dakika`}
                        </div>
                      </div>
                      <Grid container>
                        <Grid item xs={9} className="h-[30vh]">
                          <ResponsiveCalendar
                            data={getUserCalendarData(item.value)}
                            from={
                              calendarDate
                                ? `${calendarDate}`
                                : data[data.length - 1].day
                            }
                            to={calendarDate ? `${calendarDate}` : data[0].day}
                            emptyColor="#f1f1f1"
                            colors={
                              averageTime !== null
                                ? ["#EC4F34FF", "#61cdbb"]
                                : ["#61cdbb", "#44AC9BFF", "#FAC898", "#f47560"]
                            }
                            minValue={
                              averageTime === null ? undefined : averageTime
                            }
                            maxValue={
                              averageTime === null ? undefined : averageTime
                            }
                            margin={{ right: 10, left: 20, top: 25, bottom: 0 }}
                            monthBorderColor="#ffffff"
                            dayBorderWidth={2}
                            dayBorderColor="#ffffff"
                            onClick={(e) => {
                              if (e.color === "#f1f1f1") {
                                return;
                              }

                              const selectedWorks = item.value.filter(
                                (a) => a.startedDate.slice(0, 10) === e.day
                              );
                              setSelectedWorks(selectedWorks);
                              setIsOpenWorksDialog(true);
                            }}
                            legends={[
                              {
                                anchor: "bottom-right",
                                direction: "row",
                                translateY: 36,
                                itemCount: 4,
                                itemWidth: 42,
                                itemHeight: 36,
                                itemsSpacing: 14,
                                itemDirection: "right-to-left",
                              },
                            ]}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          className="h-[30vh] flex justify-center items-start p-0 m-0"
                          sx={{
                            padding: "0px !important",
                            margin: "0px !important",
                          }}
                        >
                          <ReactApexcharts
                            series={userRadarData(item.value).series}
                            type="radar"
                            height="100%"
                            width="100%"
                            options={{
                              colors: ["#7367F0"],
                              chart: {
                                height: "100%",
                                width: "100%",
                                parentHeightOffset: 0,
                                toolbar: { show: false },
                                margin: 0, // Grafik çevresindeki margin'i kaldırır
                              },
                              plotOptions: {
                                radar: {
                                  size: 100, // Radar grafiğin boyutunu ayarlar
                                  offsetX: 0,
                                  offsetY: 0,
                                  polygons: {
                                    strokeColors: "transparent", // Çevresel çizgileri kaldırır
                                    fill: {
                                      colors: ["#f1f1f1", "#ffffff"],
                                    },
                                  },
                                },
                              },
                              stroke: { width: 0 },
                              fill: {
                                opacity: [1, 0.85],
                              },
                              markers: { size: 3 },
                              grid: {
                                show: false,
                              },
                              yaxis: {
                                show: false,
                                labels: { show: false }, // Y eksenindeki metinleri kaldırır
                              },
                              xaxis: {
                                labels: {
                                  show: true,
                                  style: {
                                    fontSize: "9px",
                                  },
                                },
                              },
                              labels: userRadarData(item.value).labels,
                              tooltip: {
                                enabled: true,
                                custom: function ({
                                  series,
                                  seriesIndex,
                                  dataPointIndex,
                                  w,
                                }) {
                                  const minutes =
                                    series[seriesIndex][dataPointIndex];
                                  const hours = Math.floor(minutes / 60); // Dakikayı saate çevir
                                  const remainingMinutes = minutes % 60; // Kalan dakikayı hesapla
                                  const label =
                                    w.globals.labels[dataPointIndex];
                                  return `<div style="padding: 10px; background: #fff; border: 1px solid #ccc; border-radius: 4px;">
                                           <strong>${label}</strong>: ${hours} saat ${remainingMinutes} dakika
                                        </div>`;
                                },
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
}
