import { CustomListBoxWithoutFormik } from "components/listbox";
import Loading from "components/Loading";
import CustomLottie from "components/Lottie";
import { CustomProgressBar } from "components/ProgressBar";
import RotateAnimation from "components/RotateAnimation";
import Table from "components/Table";
import { DateColumn } from "components/Table/components/date";
import { OverflowCell } from "components/Table/components/overflow";
import { NumberRangeColumnFilter } from "components/Table/Filter/NumberRange";
import CustomToolTip from "components/tooltip";
import { useEffect, useMemo, useState } from "react";
import { FiEye } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";
import { setSelectedWorks } from "store/works";
import { AppUtils } from "utils/utils";
import noData from "../../../asset/lottie/table-no-data";
import PanelDetailReport from "../panel_detail/panel_detail";

export default function PanelReportsTable() {
  const selectedWorks = useSelector((state) => state.workSlice.selectedWorks);
  const [data, setData] = useState(null);
  const dispatch = useDispatch();

  const [calendarYear, setCalenderYear] = useState(
    new Date().getFullYear().toString()
  );

  const [yearsList, setYearsList] = useState([]);
  const monthMapping = {
    Ocak: 0,
    Şubat: 1,
    Mart: 2,
    Nisan: 3,
    Mayıs: 4,
    Haziran: 5,
    Temmuz: 6,
    Ağustos: 7,
    Eylül: 8,
    Ekim: 9,
    Kasım: 10,
    Aralık: 11,
  };
  const [calendarMonth, setCalendarMonth] = useState(new Date().getMonth());

  const {
    data: works,
    isLoading,
    error,
  } = useFetchData(
    `${ApiConstants.WORKS}${
      calendarYear === "Tümü"
        ? ""
        : `?year=${calendarYear}&month=${calendarMonth}`
    }`
  );

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 2022; year--) {
      years.push(year.toString());
    }
    years.push("Tümü");
    setYearsList(years);
  }, []);

  useEffect(() => {
    if (works) {
      var _data = works.filter((a) => a.endedDate !== null);
      _data = AppUtils.groupBy(_data, "panelName");
      _data = _data.map((v) => {
        const totalUsedTime = v.value.reduce(
          (partialSum, a) => partialSum + a.usedTime,
          0
        );
        const totalTime = v.value[0].totalTime;
        const percentTime =
          totalTime === 0 ? 0 : ((totalUsedTime / totalTime) * 100).toFixed(0);

        const totalUsedBudget = v.value.reduce(
          (partialSum, a) => partialSum + a.usedBudget,
          0
        );
        const totalBudget = v.value[0].totalBudget;
        const percentBudget =
          totalBudget === 0
            ? 0
            : ((totalUsedBudget / totalBudget) * 100).toFixed(0);
        return {
          ...v,
          percentTime,
          percentBudget,
          totalUsedTime,
          totalTime,
          totalUsedBudget,
          totalBudget,
        };
      });
      setData(_data);
    }
  }, [works]);

  useEffect(() => {
    if (calendarYear === "Tümü") {
      setCalendarMonth(null); // Ay seçimini temizle
    } else {
      setCalendarMonth(new Date().getMonth()); // Varsayılan ay seçimi
    }
  }, [calendarYear]);

  const columns = useMemo(() => {
    const col = [
      {
        Header: " ",
        accessor: "",
        Filter: "",
        Cell: DetailColumn,
        export: false,
        canGroupBy: false,
      },
      {
        Header: "Pano Adı",
        accessor: "key",
        Cell: OverflowCell,
        canGroupBy: false,
      },
      {
        Header: "Pano Kodu",
        accessor: "value[0].panelCode",
        canGroupBy: false,
      },
      {
        Header: "Proje Adı",
        accessor: "value[0].projectName",
        canGroupBy: false,
      },
      {
        Header: "Müşteri",
        accessor: "value[0].companyName",
        Cell: OverflowCell,
        canGroupBy: false,
      },
      {
        Header: "Son Müşteri",
        accessor: "value[0].lastCompanyName",
        Cell: OverflowCell,
        canGroupBy: false,
      },
      {
        Header: "Başlangıç",
        accessor: "value[0].startedDate",
        Cell: DateColumn,
        type: "date",
        dateFormat: "DD-MM-YYYY",
        canGroupBy: false,
      },
      {
        Header: "Bütçe",
        accessor: "percentBudget",
        Cell: BudgetColumn,
        Filter: NumberRangeColumnFilter,
        filter: "between",
        canGroupBy: false,
      },
      {
        Header: "Saat",
        accessor: "percentTime",
        filter: "between",
        canGroupBy: false,
        Cell: TimeColumn,
        Filter: NumberRangeColumnFilter,
      },
    ];
    return col;
  }, [works]);

  const handleMonthChange = (month) => {
    setCalendarMonth(monthMapping[month]);
  };

  if (error) return <div>Error...</div>;

  return (
    <>
      {data == null ? (
        <Loading />
      ) : (
        <RotateAnimation
          isFront={selectedWorks ? true : false}
          setIsFront={() => dispatch(setSelectedWorks(null))}
          Front={buildTable()}
          Back={<PanelDetailReport />}
        />
      )}
    </>
  );

  function buildTable() {
    return (
      <>
        <div className="flex justify-end">
          <div className="flex justify-end pr-10 mb-3">
            {calendarYear !== "Tümü" && (
              <CustomListBoxWithoutFormik
                label={"Ay"}
                selected={Object.keys(monthMapping).find(
                  (key) => monthMapping[key] === calendarMonth
                )}
                setSelected={handleMonthChange}
                values={Object.keys(monthMapping)}
              />
            )}
          </div>
          <div className="flex justify-end pr-10 mb-3">
            <CustomListBoxWithoutFormik
              label={"Yıl"}
              selected={calendarYear}
              setSelected={(year) => setCalenderYear(year)}
              values={yearsList}
            />
          </div>
        </div>
        {isLoading && <Loading />}
        {Object.keys(data || []).length === 0 && (
          <div className="flex flex-col h-max justify-center  items-center text-slate-500 pt-20 ">
            <CustomLottie animationData={noData} />
            <div className="flex text-2xl  ">Kayıt bulunamadı...</div>
          </div>
        )}

        {!isLoading && Object.keys(data || []).length > 0 && (
          <Table
            className="h-full relative"
            columns={columns}
            data={data}
            pageSize={20}
            exportExcel
            fileName="Panolar"
          />
        )}
      </>
    );
  }

  function DetailColumn({ value, column, row }) {
    return (
      <button
        onClick={() => {
          dispatch(setSelectedWorks(row.original.value));
        }}
        className="bg-red-100 text-red-500 p-1.5 rounded-md  w-min mx-2 "
      >
        <FiEye />
      </button>
    );
  }
  function BudgetColumn({ value, column, row }) {
    return (
      <>
        <CustomToolTip
          content={` ${row.original.totalUsedBudget} / ${row.original.totalBudget} ₺`}
        >
          <CustomProgressBar value={value} color="#34d399" />
        </CustomToolTip>
      </>
    );
  }
  function TimeColumn({ value, column, row }) {
    return (
      <>
        <CustomToolTip
          content={` ${row.original.totalUsedTime} / ${row.original.totalTime} sa`}
        >
          <CustomProgressBar value={value} color="#60a5fa" />
        </CustomToolTip>
      </>
    );
  }
}
