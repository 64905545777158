import { Icon } from "@iconify/react";
import { Box, Card, Typography } from "@mui/material";
import CustomDialog from "components/dialog";
import WorkReportsTable from "pages/reports/tables/work_table";
import { useEffect, useState } from "react";
import ReactApexCharts from "react-apexcharts"; 
import { AppUtils } from "utils/utils";

export default function DivisionDistributionCard({ works }) {
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: "donut",
        fontFamily: "inherit",
        events: {
          dataPointSelection: function (event, chartContext, config) {
            const divisionName = config.w.globals.labels[config.dataPointIndex];
            const divisionWorks = works.filter(
              (w) => w.divisionName === divisionName
            );
            setSelectedDivision({
              name: divisionName,
              works: divisionWorks,
            });
            setModalOpen(true);
          },
        },
      },
      labels: [],
      colors: [
        "#6366f1",
        "#22c55e",
        "#eab308",
        "#ef4444",
        "#8b5cf6",
        "#06b6d4",
        "#f97316",
        "#ec4899",
      ],
      plotOptions: {
        pie: {
          donut: {
            size: "65%",
            labels: {
              show: true,
              total: {
                show: true,
                label: "En Yoğun Bölüm",
                formatter: function (w) {
                  const maxIndex = w.globals.seriesTotals.indexOf(
                    Math.max(...w.globals.seriesTotals)
                  );
                  return w.globals.labels[maxIndex];
                },
                style: {
                  fontSize: "14px",
                  fontFamily: "inherit",
                  fontWeight: 600,
                  color: "#1e293b",
                },
              },
              value: {
                show: true,
                fontSize: "12px",
                fontFamily: "inherit",
                fontWeight: 400,
                color: "#64748b",
                formatter: function (w) {
                  const maxValue = Math.max(...w.globals.seriesTotals);
                  return `${maxValue} saat`;
                },
              },
            },
          },
        },
      },
      stroke: {
        width: 2,
        colors: ["#fff"],
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, { seriesIndex, w }) {
          const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
          return `${((val / total) * 100).toFixed(1)}%`;
        },
        style: {
          fontSize: "11px",
          fontWeight: 500,
          colors: ["#fff"],
        },
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 1,
          left: 1,
          blur: 3,
          opacity: 0.3,
        },
      },
      tooltip: {
        enabled: true,
        theme: "light",
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const value = series[seriesIndex];
          const total = series.reduce((a, b) => a + b, 0);
          const percentage = ((value / total) * 100).toFixed(1);
          const label = w.globals.labels[seriesIndex];

          return `<div style="padding: 8px">
            <div style="font-weight: 600; color: #1e293b; margin-bottom: 4px; font-size: 13px;">
              ${label}
            </div>
            <div style="color: #475569; margin: 2px 0;">
              <span style="display: inline-block; width: 100px;">Toplam Süre:</span>
              <span style="font-weight: 500;">${value} saat</span>
            </div>
            <div style="color: #475569; margin: 2px 0;">
              <span style="display: inline-block; width: 100px;">Oran:</span>
              <span style="font-weight: 500;">${percentage}%</span>
            </div>
            <div style="color: #475569; margin: 2px 0;">
              <span style="display: inline-block; width: 100px;">Günlük Ort.:</span>
              <span style="font-weight: 500;">${(value / 30).toFixed(
                1
              )} saat/gün</span>
            </div>
          </div>`;
        },
      },
    },
  });

  useEffect(() => {
    if (works) {
      // Bölümlere göre grupla
      const divisionGroups = AppUtils.groupBy(works, "divisionName");

      // Her bölümün toplam süresini hesapla
      const divisionStats = divisionGroups.map((group) => ({
        name: group.key,
        totalTime: group.value.reduce(
          (sum, work) => sum + (work.usedTime || 0),
          0
        ),
      }));

      // En çok çalışılan 8 bölümü al
      const top8Divisions = divisionStats
        .sort((a, b) => b.totalTime - a.totalTime)
        .slice(0, 8);

      setChartData((prev) => ({
        ...prev,
        series: top8Divisions.map((d) => Math.round(d.totalTime / 60)),
        options: {
          ...prev.options,
          labels: top8Divisions.map((d) => d.name),
        },
      }));
    }
  }, [works]);

  return (
    <>
      <Card
        sx={{
          height: "400px",
          background: "linear-gradient(to bottom, #f8fafc, #ffffff)",
        }}
      >
        <Box
          sx={{
            p: 2,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Header */}
          <Box
            sx={{ display: "flex", alignItems: "flex-start", gap: 1, mb: 2 }}
          >
            <Icon icon="mdi:chart-pie" color="#3f51b5" width="20" height="20" />
            <Box sx={{ flex: 1 }}>
              <Typography variant="subtitle2" color="primary" fontWeight={600}>
                Bölüm Dağılımı
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: "text.secondary",
                  display: "block",
                  mt: 0.5,
                }}
              >
                En çok çalışma saati olan bölümlerin dağılımı ve toplam süreleri
              </Typography>
            </Box>
            <Box
              sx={{
                px: 1,
                py: 0.5,
                borderRadius: 1,
                bgcolor: "primary.main",
                color: "white",
              }}
            >
              <Typography variant="caption" fontWeight={500}>
                Top 8
              </Typography>
            </Box>
          </Box>

          {/* Content Container */}
          <Box sx={{ display: "flex", flex: 1, minHeight: 0 }}>
            {/* Chart */}
            <Box
              sx={{
                width: "60%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                ".apexcharts-canvas": {
                  background: "transparent !important",
                  width: "100% !important",
                },
              }}
            >
              <ReactApexCharts
                options={chartData.options}
                series={chartData.series}
                type="donut"
                height="100%"
                width="100%"
              />
            </Box>

            {/* Custom Legend */}
            <Box
              sx={{
                width: "40%",
                overflowY: "auto",
                pl: 2,
                pr: 1,
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#888",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "#555",
                },
              }}
            >
              {chartData.options.labels.map((label, index) => (
                <Box
                  key={label}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 0.5,
                    py: 0.5,
                    px: 1,
                    borderRadius: 1,
                    "&:hover": {
                      bgcolor: "rgba(0,0,0,0.04)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: 8,
                      height: 8,
                      borderRadius: "50%",
                      bgcolor: chartData.options.colors[index],
                      mr: 1,
                    }}
                  />
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 500,
                        color: "text.primary",
                        fontSize: "0.75rem",
                        lineHeight: 1.2,
                      }}
                    >
                      {label}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        color: "text.secondary",
                        display: "block",
                        fontSize: "0.7rem",
                        lineHeight: 1.1,
                      }}
                    >
                      {chartData.series[index]} saat
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Card>

      <CustomDialog
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        title={`${selectedDivision?.name} - İş Kayıtları`}
        maxWidth="max-w-7xl"
        body={<WorkReportsTable worksData={selectedDivision?.works || []} />}
      />
    </>
  );
}
