class ApiConstants {
  static = 5000;
  static getToken = localStorage.getItem("tdk_acces_token");
  static BASEURL = process.env.REACT_APP_API_BASE_URL;
  static PF = process.env.REACT_APP_PUBLIC_FOLDER;
  static headers = {
    Authorization: "Bearer " + this.getToken,
  };

  // AUTH
  // static AUTH = "/auth";
  static register = "/auth/register";
  static login = "/auth/login";

  // USERS
  static USERS = "/users";
  static addProfilePicture = "/users/add-profile-picture";

  // COMPANY
  static COMPANIES = "/companies";

  // PANEL
  static PANELS = "/panels";

  // PROJECT
  static PROJECTS = "/projects";
  static COMPLETE_ALL_WORK = "/completeAllWork";

  // DIVISION
  static DIVISIONS = "/divisions";

  // WORK
  static WORKS = "/works";
  static ACTIVE_WORKS = "/works/activeWorks";
  static completeAllWork = "/works/completeAllWork";
  static getWorksForMining = "/works/forMining";
  static getEvents = "/events";
  static getReports = "getreports";
  // WORKING_HOURS
  static WORKING_HOURS = "/workingHours";
}
export default ApiConstants;
