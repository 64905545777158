import { ProgressBar } from "primereact/progressbar";

export function CustomProgressBar({ value, color }) {
  return (
    <div className="relative">
      <ProgressBar
        className="text-xs cursor-help "
        value={value}
        color={value > 100 ? "#ef4444" : color}
      />
      <div
        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  text-xs text-white `}
      >
        {value + " %"}
      </div>
    </div>
  );
}
