import { useState } from "react";
import { MdUploadFile } from "react-icons/md";
import * as XLSX from "xlsx";

const ExcelUploader = ({ onDone }) => {
  const [loading, setLoading] = useState(false);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      setLoading(true);
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);

        // Extract and calculate total adam saat and total cost
        let totalAdamSaat = 0;
        let totalMaliyet = 0;
        jsonData.forEach((row) => {
          const adet = parseFloat(row["ADET"]) || 0;
          const birimMik = parseFloat(row["A/S Birim Mik"]) || 0;
          const maliyet = parseFloat(row["TOPLAM"]) || 0; // E sütunundaki maliyeti al
          totalAdamSaat += adet * birimMik;
          totalMaliyet += maliyet; // Maliyeti topla
        });

        setLoading(false);
        onDone(totalAdamSaat, totalMaliyet); // İki değeri de ilet

        // Input değerini sıfırla
        event.target.value = null;
      };

      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileUpload}
        style={{ display: "none" }}
        id="file-upload"
      />
      <label
        htmlFor="file-upload"
        className={`flex items-center justify-center gap-x-2 p-2 rounded-md border border-gray-300 cursor-pointer ${
          loading ? "spin" : ""
        }`}
      >
        <MdUploadFile width={20} height={20} />
      </label>
    </div>
  );
};

export default ExcelUploader;

/* CSS for spinner animation */
const style = document.createElement("style");
style.textContent = `
  .spin {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
document.head.append(style);
