import * as React from "react";

import Box from "@mui/material/Box";
import { unstable_useForkRef as useForkRef } from "@mui/utils";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { unstable_useDateField as useDateField } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/tr";

import { DateTimePicker } from "@mui/x-date-pickers";
import { useClearableField } from "@mui/x-date-pickers/hooks";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useState } from "react";
import WorkService from "services/works_service";
import { showError, showSucces } from "utils/error";

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("Europe/Istanbul");

const BrowserField = React.forwardRef((props, ref) => {
  const {
    disabled,
    id,
    label,
    inputRef,
    InputProps: { ref: containerRef, startAdornment, endAdornment } = {},
    // extracting `error`, 'focused', and `ownerState` as `input` does not support those props
    error,
    focused,
    ownerState,
    sx,
    ...other
  } = props;

  const handleRef = useForkRef(containerRef, ref);

  return (
    <Box
      sx={{ ...(sx || {}), display: "flex", alignItems: "center" }}
      id={id}
      ref={handleRef}
    >
      {startAdornment}
      <input disabled={disabled} ref={inputRef} {...other} />
      {endAdornment}
    </Box>
  );
});

const BrowserDateField = React.forwardRef((props, ref) => {
  const {
    inputRef: externalInputRef,
    slots,
    slotProps,
    ...textFieldProps
  } = props;

  const {
    onClear,
    clearable,
    ref: inputRef,
    ...fieldProps
  } = useDateField({
    props: textFieldProps,
    inputRef: externalInputRef,
  });

  /* If you don't need a clear button, you can skip the use of this hook */
  const { InputProps: ProcessedInputProps, fieldProps: processedFieldProps } =
    useClearableField({
      onClear,
      clearable,
      fieldProps,
      InputProps: fieldProps.InputProps,
      slots,
      slotProps,
    });
  return (
    <BrowserField
      ref={ref}
      inputRef={inputRef}
      {...processedFieldProps}
      InputProps={ProcessedInputProps}
    />
  );
});

export default function PickerWithBrowserField({ value, column, row }) {
  const [date, setDate] = useState(dayjs(value));
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">
      <DateTimePicker
        slots={{ field: BrowserDateField }}
        defaultValue={date}
        value={date}
        
        onAccept={(newValue) => {
          const newDate = newValue.utc().format("YYYY-MM-DD HH:mm:ss");
          console.log(newDate);
          console.log({
            id: row.original._id,
            data: { [column.id]: newDate },
          });
          WorkService.updateWork({
            id: row.original._id,
            data: { [column.id]: newDate },
          })
            .then((res) => {
              showSucces("Tarih güncellendi");
            })
            .catch((err) => {
              console.log(err);
              showError(err?.response?.data ?? "Bir hata oluştu");
            });
        }}
        minutesStep={1}
        timeSteps={1}
      />
    </LocalizationProvider>
  );
}
