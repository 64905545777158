import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";
import ActiveUsersCard from "./components/active_users_card";
import DivisionDistributionCard from "./components/division_distribution_card";
import HomePageV3_Events from "./components/event";
import GeneralInfoCard from "./components/general_info_card";
import ProjectsOverviewCard from "./components/projects_overview_card";
import ShiftTableCard from "./components/shift_table_card";
import TopPerformersCard from "./components/top_performers_card";
import WorkloadTrendCard from "./components/workload_trend_card";

export default function HomePageV3() {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [loadingMessage, setLoadingMessage] = useState("");

  const { data: works } = useFetchData(
    `${ApiConstants.WORKS}?year=${selectedYear}`
  );

  const { data: activeWorks } = useFetchData(ApiConstants.ACTIVE_WORKS);

  useEffect(() => {
    setLoadingMessage(`${selectedYear} yükleniyor...`);
  }, [selectedYear]);

  useEffect(() => {
    if (works) {
      setLoadingMessage(`${selectedYear} yüklendi`);
    }
  }, [works, selectedYear]);

  return (
    <div>
      <div className="flex justify-start mb-4 gap-4">
        <select
          className="flex border-0 bg-[#f5f5f5] rounded-md text-sm  appearance-none"
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
        >
          {["2022", "2023", "2024", "2025", "2026", "2027"].map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>{" "}
        <div className="flex items-center mr-2 text-sm text-gray-500">
          {loadingMessage}
        </div>
      </div>
      <Grid container xs={12} spacing={1} className="h-base overflow-hidden">
        <Grid
          item
          container
          xs={10}
          spacing={1}
          direction="row"
          className="h-min"
          sx={{
            justifyContent: "center",
            alignItems: "stretch",
            height: "calc(100vh - 64px)",
            overflowY: "auto",
            paddingBottom: "5rem",
          }}
        >
          <Grid item sm={8}>
            <ShiftTableCard currentYear={selectedYear} />
          </Grid>
          <Grid item sm={4}>
            <GeneralInfoCard />
          </Grid>
          <Grid item sm={8}>
            <ProjectsOverviewCard works={works} currentYear={selectedYear} />
          </Grid>
          <Grid item sm={4}>
            <ActiveUsersCard activeWorks={activeWorks} />
          </Grid>
          <Grid item sm={4}>
            <DivisionDistributionCard works={works} />
          </Grid>
          <Grid item sm={4}>
            <TopPerformersCard works={works} />
          </Grid>
          <Grid item sm={4}>
            <WorkloadTrendCard works={works} />
          </Grid>
        </Grid>

        <Grid item xs={2} sx={{ height: "100%" }}>
          <HomePageV3_Events />
        </Grid>
      </Grid>
    </div>
  );
}
