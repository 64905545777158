import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Header from "components/Header";
import Sidebar from "components/Sidebar";
import { Outlet } from "react-router-dom";


export default function HomeLayout() {
  return ( 
      <div className="App h-full flex bg-[#F8F7FA]">
        <Sidebar />
        <div className="flex flex-col w-full overflow-auto">
          <Header />
          <div className="px-3">
            <Outlet />
          </div>
        </div>
      </div> 
  );
}
