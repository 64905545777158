import { Icon } from "@iconify/react";
import { Box, Card, Grid, Typography } from "@mui/material";
import homeCardIllustration from "asset/images/home-card.png";
import CustomButton from "components/Form/Button";
import CustomDialog from "components/dialog";
import { useState } from "react";
import { IoIosWarning } from "react-icons/io";
import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";
import SocketService from "services/socket";
import WorkService from "services/works_service";

export default function GeneralInfoCard() {
  const [dialog, setDialog] = useState(false);
  const [bellDialog, setBellDialog] = useState(false);
  const [continueWork, setContinueWork] = useState(false);

  const { data: users, isLoading: usersLoading } = useFetchData(
    ApiConstants.USERS
  );
  const { data: works, isLoading: worksLoading } = useFetchData(
    ApiConstants.ACTIVE_WORKS
  );

  if (usersLoading || worksLoading) {
    return (
      <Card sx={{ height: "100%", bgcolor: "#7367F0" }}>
        <Box sx={{ p: 3, height: "100%" }} className="animate-pulse">
          <div className="h-6 bg-primary300 rounded w-1/3 mb-2" />
          <div className="h-4 bg-primary300 rounded w-1/4" />
        </Box>
      </Card>
    );
  }

  const ActionButton = ({ icon, label, onClick, animate }) => (
    <button
      onClick={onClick}
      className="flex items-center gap-2 px-3 py-2 rounded-lg transition-all bg-primary300 hover:bg-primary400"
    >
      <Icon
        icon={icon}
        className={`text-white text-xl ${
          animate ? "hover:animate-vibrate" : ""
        }`}
      />
      <Typography
        variant="caption"
        sx={{ color: "common.white", fontWeight: 500 }}
      >
        {label}
      </Typography>
    </button>
  );

  const StatBox = ({ value, label }) => (
    <div className="flex items-center gap-2">
      <div className="px-3 py-2 rounded-lg bg-primary300 text-white font-medium">
        {value}
      </div>
      <Typography variant="caption" sx={{ color: "common.white" }}>
        {label}
      </Typography>
    </div>
  );

  return (
    <>
      {buildDialogs()}

      <Card sx={{ bgcolor: "#7367F0", height: "100%" }}>
        <Box sx={{ p: 3, height: "100%" }} className="flex flex-col">
          <div className="mb-4">
            <Typography
              variant="subtitle1"
              sx={{ color: "common.white", fontWeight: 600 }}
            >
              Genel Bilgiler
            </Typography>
            <Typography
              variant="caption"
              sx={{ color: "rgba(255,255,255,0.7)" }}
            >
              Çalışma Sahası
            </Typography>
          </div>

          <Grid container spacing={3} className="flex-1">
            <Grid item container xs={12} md={8} spacing={2}>
              <Grid item xs={6}>
                <StatBox value={users?.length || 0} label="Personel" />
              </Grid>
              <Grid item xs={6}>
                <ActionButton
                  icon="heroicons:power-16-solid"
                  label="Bitir"
                  onClick={() => setDialog(true)}
                />
              </Grid>
              <Grid item xs={6}>
                <StatBox value={works?.length || 0} label="Çalışıyor" />
              </Grid>
              <Grid item xs={6}>
                <ActionButton
                  icon="noto-v1:bell"
                  label="Zil"
                  onClick={() => setBellDialog(true)}
                  animate
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              className="flex justify-center items-center"
            >
              <img
                src={homeCardIllustration}
                alt="home-card"
                className="max-h-[120px] drop-shadow-xl"
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );

  function buildDialogs() {
    return (
      <>
        <CustomDialog
          isOpen={bellDialog}
          setIsOpen={setBellDialog}
          title="Uyarı"
          body={
            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-2">
                <IoIosWarning className="h-14 w-14 text-red-500" />
                <Typography>
                  Zili çalmak üzeresiniz devam etmek istiyor musunuz?
                </Typography>
              </div>

              <div className="flex justify-end gap-2">
                <CustomButton
                  className="text-slate-400"
                  onClick={() => setBellDialog(false)}
                >
                  Vazgeç
                </CustomButton>
                <CustomButton
                  className="bg-blue-100 text-blue-800 font-semibold"
                  onClick={() => {
                    setBellDialog(false);
                    SocketService.emitBell();
                  }}
                >
                  Devam et
                </CustomButton>
              </div>
            </div>
          }
        />

        <CustomDialog
          isOpen={dialog}
          setIsOpen={setDialog}
          title={"Uyarı"}
          body={
            <div className="flex flex-col items-end">
              <div>
                <div className="flex justify-center items-center gap-x-2">
                  <IoIosWarning className="h-14 w-14 text-red-500" />
                  Çalışan bütün personellerin mevcut işleri sonlandırılacak.
                  İşleme devam etmek istiyor musunuz?
                </div>
                <div className="mt-4 mx-4 flex items-center gap-x-2">
                  <input
                    type="checkbox"
                    id="continueWork"
                    checked={continueWork}
                    onChange={(e) => setContinueWork(e.target.checked)}
                    className="w-4 h-4"
                  />
                  <label htmlFor="continueWork" className="text-sm">
                    {"İşler devam etsin mi ? "}
                    {continueWork ? "Evet" : "Hayır"}
                  </label>
                </div>
              </div>
              <div className="flex gap-x-4 mt-4">
                <CustomButton
                  className="text-slate-400 font-thin"
                  onClick={() => setDialog(false)}
                >
                  Vazgeç
                </CustomButton>
                <CustomButton
                  className="bg-red-100 text-red-800 font-bold py-2"
                  onClick={() => {
                    WorkService.completeAllWork({
                      query: `?continue=${continueWork ? 1 : 0}`,
                    });
                    SocketService.emitBell();
                    setDialog(false);
                  }}
                >
                  Devam et
                </CustomButton>
              </div>
            </div>
          }
        />
      </>
    );
  }
}
