import Loading from "components/Loading";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppUtils } from "utils/utils";
import { MdOutlineCancel } from "react-icons/md";
import CustomNivoCalendar from "components/Nivo/calendar";
import { CustomListBoxWithoutFormik } from "components/listbox";
import Table from "components/Table";

export default function PanelDetailReportTable() {
  const selectedWorks = useSelector((state) => state.workSlice.selectedWorks);
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [divisions, setDivisions] = useState(null);
  const [calendarDate, setCalendarDate] = useState( new Date().getFullYear().toString());
  const [calendarData, setCalendarData] = useState([]);
  const [filterDate, setFilterDate] = useState(null);

  const getCompletedWorks = () =>
    selectedWorks.map((v) => {
      const startedDateOnlyDate = v.startedDate.slice(0, 10);
      const endedDateOnlyDate = v.endedDate.slice(0, 10);
      return { ...v, startedDateOnlyDate, endedDateOnlyDate };
    });
  useEffect(() => {
    if (selectedWorks) {
      
      var completedWorks = getCompletedWorks();
      if (filterDate) {
        completedWorks = completedWorks.filter(
          (v) => v.startedDateOnlyDate === filterDate
        );
      }
      var divisions = AppUtils.groupBy(completedWorks, "divisionName");
      setDivisions(divisions);
      var _data = AppUtils.groupBy(completedWorks, "user");

      var _data = _data.map((v) => {
        var _newData = { ...v };
        divisions.map((d) => {
          const _totalHour = parseInt(
            d.value
              .filter((u) => u.user === v.key)
              .reduce((partialSum, a) => partialSum + a.usedTime, 0)
              .toFixed(0)
          );
          _newData[d.key] = _totalHour;
        });
        _newData["total"] = parseInt(
          v.value
            .reduce((partialSum, a) => partialSum + a.usedTime, 0)
            .toFixed(0)
        );
        return _newData;
      });

      setData(_data);
    }
  }, [selectedWorks, filterDate]);
  useEffect(() => {
    if (selectedWorks) {
      var completedWorks = getCompletedWorks();
      var _calendarData = AppUtils.groupBy(
        completedWorks,
        "startedDateOnlyDate"
      );
      _calendarData = _calendarData.map((v) => {
        var _totalUsedTime = v.value
          .reduce((partialSum, a) => partialSum + a.usedTime, 0)
          .toFixed(0);
        return {
          value: _totalUsedTime,
          day: v.key,
        };
      });
      setCalendarData(_calendarData);
    }
  }, [selectedWorks]);

  const columns = useMemo(() => {
    const col = [
      {
        Header: "Personel",
        accessor: "key",
        canGroupBy: false,
        Footer: "Toplam",
      },
      {
        Header: "Toplam",
        accessor: "total",
        canGroupBy: false,
        Footer: (info) => {
          const total = React.useMemo(
            () => info.rows.reduce((sum, row) => row.values.total + sum, 0),
            [info.rows]
          );
          return <> {total}</>;
        },
      },
    ];
    divisions &&
      divisions.map((v) =>
        col.push({
          Header: v.key + " (dk)",
          accessor: `${v.key}`,
          canGroupBy: false,
          Footer: (info) => {
            const total = React.useMemo(
              () => info.rows.reduce((sum, row) => row.values[v.key] + sum, 0),
              [info.rows]
            );
            return <> {total}</>;
          },
        })
      );
    return col;
  }, [data, divisions]);
  console.log(data);
  return <>{data == null ? <Loading /> : buildBody()}</>;

  function buildBody() {
    return (
      <div className="mt-4  ">
        <div className="text-lg font-semibold">Çalışmalar</div>
        {calendarData && (
          <div className="h-[300px]">
            <div className="flex  justify-end pr-10  ">
              <CustomListBoxWithoutFormik
                label={"Yıl"}
                selected={calendarDate}
                setSelected={setCalendarDate}
                values={["2022", "2023", "2024", "2025", "2026", "2027"]}
              />
            </div>
            <CustomNivoCalendar
              data={calendarData}
              date={calendarDate}
              onClick={(event) => setFilterDate(event.day)}
            />
          </div>
        )}
        <div className="flex gap-x-2 ">
          <div className="bg-emerald-100 m-3 relative py-1 px-2 rounded-md  flex w-28 justify-center items-center text-emerald-700  font-bold">
            {filterDate || "Genel"}
            {filterDate && (
              <div
                className="absolute top-1/2 cursor-pointer -right-5 transform -translate-y-1/2 rounded-full bg-slate-100 text-slate-700"
                onClick={() => setFilterDate(null)}
              >
                <MdOutlineCancel className="w-4 h-4" />
              </div>
            )}
          </div>
        </div>
        {(data !== null) & (data.length != 0) && buildTable()}
      </div>
    );
  }
  function buildTable() {
    return (
      <>
        <Table
          className="h-full relative mt-4"
          columns={columns}
          data={data}
          pageSize={50}
          exportExcel
          fileName="Panolar"
        />
      </>
    );
  }
}
