import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css"; //core css
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./App.css";
import store from "./store";

import { createTheme, ThemeProvider } from "@mui/material";
import "devextreme/dist/css/dx.light.css";
import "reactflow/dist/style.css";
import "./global.css";
import "./style.css";
import 'react-perfect-scrollbar/dist/css/styles.css';


const theme = createTheme({
  palette: {
    primary: {
      main: "#7367F0",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          padding: "0.2rem",
          boxShadow: "0px 1px 1px 1px rgba(0,0,0,0.1)",
        },
      },
      
    }, MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "0.5rem",  
          //last child
          "&:last-child": {
            paddingBottom: "0.5rem",
          },
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
document.title = "Tedak Admin";
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
);
