import React, { useState } from "react";
import loginImg from "asset/images/admin_login.svg";
import { Form, Formik } from "formik";
import CustomButton from "components/Form/Button";
import Input from "components/Form/Input";
import tedakLogo from "asset/images/tedak.svg";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { showError } from "utils/error";
import { login } from "store/auth";
import AuthServices from "services/auth_service";

export default function AdminLoginPage() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authSlice.user);
  if (user) {
    return <Navigate to={"/admin"} replace={true} />;
  }
  return (
    <div className="flex bg-white h-full ">
      <div className="  pt-6 flex  flex-shrink-0 w-1/5  bg-red-500 drop-shadow-xl rounded-r-3xl justify-start   flex-col items-center">
        <img src={tedakLogo} alt="" className="px-16  pt-24" />
        <div className="h-full" />
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            username: "",
            password: "",
          }}
          onSubmit={async (values, actions) => {
            if (values.username && values.password) {
              setLoading(true);
              try {
                const user = await AuthServices.login(values);  
                if (user?.role === "Super Admin" || user?.role === "Admin") {
                  actions.resetForm();
                  dispatch(login(user));
                } else {
                  setLoading(false);
                  showError("Yetkisiz giriş denemesi");
                }
              } catch (error) { 
                setLoading(false);
                showError(error);
              }
              setLoading(false);
            }
          }}
        >
          {({ values }) => (
            <Form className="gap-y-4 flex flex-col  w-full px-8 ">
              <div className="flex  flex-col gap-y-2">
                <Input type="text" label="Kullanıcı Adı" name="username" />
                <Input type="password" label="Şifre" name="password" />
              </div>
              <div className="flex w-full justify-evenly pt-3 self-end">
                <CustomButton
                  type="submit"
                  loading={loading}
                  className={
                    " bg-gray-700 flex  p-2 text-white w-full justify-center"
                  }
                >
                  {"Giriş Yap"}
                </CustomButton>
              </div>
            </Form>
          )}
        </Formik> 
        <div className="h-full" />
        <div className="h-full" /> <div className="h-full" />
      </div>
      <div className=" p-10 flex w-full justify-start flex-col gap-y-4 items-center  ">
        <div className=" text-2xl  py-4  font-semibold text-slate-600"> 
        Elektrik Panosu Üretim Yönetim Sistemi
        </div>
        <img src={loginImg} alt="" className="h-full p-8" />
        <div className=" text-2xl  py-4 font-semibold text-slate-700"> 
        E-PANOSİS
        </div>
      </div>
      <Toaster position="top-right" />
    </div>
  );
}
