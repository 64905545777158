import CustomDialog from "components/dialog";
import CustomButton from "components/Form/Button";
import { DateInput } from "components/Form/DatePicker";
import Input from "components/Form/Input";
import CustomInputNumber from "components/Form/InputNumber";
import { CustomListBox } from "components/Form/listbox";
import { Form, Formik } from "formik";
import { classNames } from "primereact/utils";
import { useEffect, useState } from "react";
import { IoIosWarning } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";
import PanelService from "services/panel_service";
import { setPanel } from "store/panel";
import { mutate } from "swr";
import AppConstants from "utils/constants/app_constants";
import { showError, showSucces } from "utils/error";
import ExcelUploader from "./excel-upload";

export default function EditPanel() {
  const panel = useSelector((state) => state.panelSlice.panel);
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [toplamSure, setToplamSure] = useState(undefined);
  const dispatch = useDispatch();

  const [isUpdate, setIsUpdate] = useState(false);

  const {
    data: projects,
    isLoading,
    error,
  } = useFetchData(ApiConstants.PROJECTS);
  const {
    data: divisions,
    isLoading: divisionIsLoading,
    error: divisionError,
  } = useFetchData(ApiConstants.DIVISIONS);

  useEffect(() => {
    if (panel) {
      setToplamSure(undefined);
      if (panel._id) {
        setIsUpdate(true);
      } else {
        setIsUpdate(false);
      }
    } else {
      setIsUpdate(false);
    }
  }, [panel]);

  if (isLoading || divisionIsLoading) return <div>Loading...</div>;
  if (error || divisionError) return <div>Error...</div>;

  return (
    <>
      <CustomDialog
        isOpen={dialog}
        setIsOpen={setDialog}
        title={"Uyarı"}
        body={
          <div className="flex  flex-col  items-end">
            <div>
              <div className="flex justify-center items-center gap-x-2">
                <IoIosWarning className="h-14 w-14 text-red-500"></IoIosWarning>
                Panoyu silmek üzeresiniz. Bu işlem geri alınamaz. Devam etmek
                istediğinize emin misiniz?
              </div>
            </div>
            <div className="flex gap-x-4">
              <CustomButton
                className="text-slate-400 font-thin"
                onClick={() => setDialog(false)}
              >
                Vazgeç
              </CustomButton>
              <CustomButton
                className="bg-red-100 text-red-800 font-bold py-2"
                onClick={async () => {
                  try {
                    setDialog(false);
                    await PanelService.delete(panel._id);
                    dispatch(setPanel(null));
                    mutate(ApiConstants.PANELS);
                    showSucces("Pano silindi");
                  } catch (error) {
                    setLoading(false);
                    showError(error);
                  }
                }}
              >
                Devam et
              </CustomButton>
            </div>
          </div>
        }
      ></CustomDialog>
      <div
        className={classNames(
          "shadow rounded-lg flex-col items-center divide-y gap-4 transition-all bg-white p-4  flex-shrink-0 "
        )}
      >
        <div className="">
          <span className="text-red-400 font-bold text-lg self-start ">
            {isUpdate ? "Pano Güncelle" : "Pano Ekle"}
          </span>
          <div className="h-[1px] bg-slate-100 w-full my-2"></div>
          <Formik
            enableReinitialize={true}
            validateOnChange={true}
            validateOnBlur={false}
            initialValues={{
              panelName: panel?.panelName || "",
              panelCode: panel?.panelCode || "",
              projectName: panel?.projectName || "",
              state: panel?.state || "",
              startedDate: new Date(panel?.startedDate),
              endedDate: new Date(panel?.endedDate),
              transferDate: new Date(panel?.transferDate),
              totalTime: toplamSure || panel?.totalTime || 0,
              totalBudget: panel?.totalBudget || 0,
              divisions: panel?.divisions.map((v) => v.divisionName) || [],
            }}
            onSubmit={async (values, actions) => {
              try {
                setLoading(true);
                const data = {
                  ...values,
                  divisions: divisions.filter((v) =>
                    values.divisions.includes(v.divisionName)
                  ),
                  projectID: projects.find(
                    (val) => values.projectName === val.projectName
                  )._id,
                  companyID: projects.find(
                    (val) => values.projectName === val.projectName
                  ).companyID,
                  lastCompanyID: projects.find(
                    (val) => values.projectName === val.projectName
                  ).lastCompanyID,
                };
                console.log("gitmeden önceki", data);

                if (isUpdate) {
                  await PanelService.update(panel._id, data);
                  showSucces("Panel güncellendi");
                } else {
                  await PanelService.add(data);
                  actions.resetForm();
                  showSucces("Panel kaydedildi");
                }

                mutate(ApiConstants.PANELS);
              } catch (error) {
                setLoading(false);
                showError(error);
              }
              setLoading(false);
            }}
          >
            {({ setFieldValue, values }) => (
              <Form
                enableReinitialize={true}
                autoComplete="off"
                className="gap-y-4 pt-4 flex flex-col     "
              >
                <Input type="text" label="Pano Adı" name="panelName" required />
                <Input
                  type="text"
                  label="Pano Kodu"
                  name="panelCode"
                  required
                />
                <CustomListBox
                  label={"Proje"}
                  name="projectName"
                  values={projects.map((project) => project.projectName)}
                />
                <CustomListBox
                  label="Durum"
                  name="state"
                  values={AppConstants.panoMode.map((mode) => mode)}
                />
                <DateInput label="Başlangıç tarihi" name="startedDate" />
                <DateInput label="Bitiş tarihi" name="endedDate" />
                <DateInput label="Sevk tarihi" name="transferDate" />

                <CustomListBox
                  label="Bölümler"
                  name="divisions"
                  multiple={true}
                  values={divisions.map((division) => division.divisionName)}
                />
                <div className="flex gap-x-4 items-center justify-center">
                  <div className="w-full">
                    <CustomInputNumber
                      label="Toplam süre"
                      name="totalTime"
                      mode="decimal"
                      suffix={" saat"}
                      required
                    />
                  </div>
                  <ExcelUploader
                    onDone={(totalAdamSaat, totalMaliyet) => {
                      showSucces("Toplam süre hesaplandı");
                      setFieldValue("totalTime", Math.round(totalAdamSaat));
                      setFieldValue("totalBudget", Math.round(totalMaliyet));
                      setToplamSure(Math.round(totalAdamSaat));
                    }}
                  />
                </div>
                <CustomInputNumber
                  label="Toplam bütçe"
                  name="totalBudget"
                  mode="decimal"
                  suffix={" ₺"}
                  required
                />

                <div className="flex w-full justify-evenly pt-3 self-end">
                  {isUpdate && (
                    <CustomButton
                      onClick={() => setDialog(true)}
                      type="reset"
                      className=" hover:bg-red-100 text-red-500 py-2 "
                    >
                      Sil
                    </CustomButton>
                  )}
                  <CustomButton
                    onClick={() => {
                      dispatch(setPanel(null));
                      setToplamSure(undefined);
                    }}
                    type="reset"
                    className=" hover:bg-gray-100 text-gray-400 py-2  "
                  >
                    Temizle
                  </CustomButton>

                  <CustomButton
                    type="submit"
                    className=" bg-red-50 text-red-700 hover:bg-red-100   py-2"
                    loading={loading ? true : false}
                  >
                    {isUpdate ? "Güncelle" : "Ekle"}
                  </CustomButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
