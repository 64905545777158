import { Card, CardContent, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ReactApexcharts from "react-apexcharts";
import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";
import { AppUtils } from "utils/utils";

// const series = [{ name: "Sales", data: [32, 27, 27, 30, 25, 25] }];

export default function HomePageV2_DivisionChart() {
  const { data: workingUsers } = useFetchData(`${ApiConstants.ACTIVE_WORKS}`);
  const [labels, setLabels] = useState([]);
  const [series, setSeries] = useState([]); 
  useEffect(() => {
    if (workingUsers) {
      // groupBy divisionName
      var _data = AppUtils.groupBy(workingUsers, "divisionName");
      const labelData = [];
      const rawSeries = _data.map((v) => {
        labelData.push(`${v.key} (${v.value.length})`);
        return v.value.length;
      });

      setLabels(labelData);
      setSeries([{ name: "Bölüm", data: rawSeries }]);
    }
  }, [workingUsers]);

  return (
    <Card>
      <CardContent className="flex flex-col justify-between  ">
        <div className="flex items-center  justify-between w-full px-1 ">
          <Typography variant="body2" className=" text-gray-600 font-semibold">
            Personel - İş Dağılımı
          </Typography>
        </div>
        <div className="  ">
          <ReactApexcharts
            series={series}
            type="radar"
            height={373}
            width="100%"
            options={{
              colors: ["#7367F0"],
              chart: {
                parentHeightOffset: 0,
                toolbar: { show: false },
              },

              stroke: { width: 0 },
              fill: {
                opacity: [1, 0.85],
              },
              markers: { size: 3 },
              plotOptions: {
                radar: {
                  size: 100, // Radar grafiğin boyutunu ayarlar
                  offsetX: 0,
                  offsetY: 0,
                  polygons: {
                    strokeColors: "transparent", // Çevresel çizgileri kaldırır
                    fill: {
                      colors: ["#f1f1f1", "#ffffff"],
                    },
                  },
                },
              },

              grid: { show: false },
              yaxis: { show: false },
              xaxis: {
                labels: {
                  show: true,
                  style: {
                    fontSize: "10px",
                    colors: labels.map((v) => {
                      return "#4E4E4EFF";
                    }),
                  },
                },
              },
              labels: labels,
              responsive: [
                {
                  breakpoint: 1200,
                  options: {
                    chart: {
                      height: 332,
                    },
                  },
                },
              ],
            }}
          />
        </div>
      </CardContent>
    </Card>
  );
}
