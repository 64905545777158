import { Icon } from "@iconify/react";
import {
  Avatar,
  Box,
  Card,
  Chip,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

export default function ActiveUsersCard({ activeWorks }) {
  const getTimeDiff = (startedDate) => {
    const minute = (new Date() - new Date(startedDate)) / (60 * 1000);
    return `${
      Math.floor(minute / 60) > 0 ? `${Math.floor(minute / 60)} sa` : ""
    } ${Math.floor(minute % 60)} dk`;
  };

  return (
    <Card
      sx={{
        height: "400px",
        background: "linear-gradient(to bottom, #f8fafc, #ffffff)",
      }}
    >
      <Box
        sx={{ p: 2, height: "100%", display: "flex", flexDirection: "column" }}
      >
        {/* Header */}
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1, mb: 2 }}>
          <Icon icon="mdi:briefcase" color="#3f51b5" width="20" height="20" />
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle2" color="primary" fontWeight={600}>
              Aktif Çalışanlar ({activeWorks?.length || 0})
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: "text.secondary",
                display: "block",
                mt: 0.5,
              }}
            >
              Şu anda sistemde aktif olarak çalışan personellerin anlık durumu
            </Typography>
          </Box>
        </Box>

        {/* Content */}
        <Box className="flex-1 overflow-auto custom-scrollbar">
          <Stack spacing={1.5}>
            {activeWorks?.map((work) => (
              <Box
                key={work._id}
                sx={{
                  p: 1.5,
                  borderRadius: 1,
                  backgroundColor: "white",
                  boxShadow: "0 1px 3px rgba(0,0,0,0.05)",
                  "&:hover": {
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    transform: "translateY(-1px)",
                    transition: "all 0.2s ease",
                  },
                }}
              >
                <Stack direction="row" alignItems="center" spacing={1.5}>
                  <Avatar
                    src={work.userProfile}
                    sx={{
                      width: 38,
                      height: 38,
                      border: "2px solid #fff",
                      boxShadow: "0 0 0 2px rgba(59, 130, 246, 0.1)",
                    }}
                  />

                  <Box sx={{ flex: 1, minWidth: 0 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 600,
                        color: "text.primary",
                        lineHeight: 1.2,
                      }}
                    >
                      {work.user}
                    </Typography>

                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      sx={{ mt: 0.5 }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          color: "text.secondary",
                          display: "flex",
                          alignItems: "center",
                          gap: 0.5,
                        }}
                      >
                        <Box
                          component="span"
                          sx={{
                            width: 6,
                            height: 6,
                            borderRadius: "50%",
                            backgroundColor: "success.main",
                            display: "inline-block",
                          }}
                        />
                        {work.panelName}
                      </Typography>

                      <Typography
                        variant="caption"
                        sx={{
                          color: "text.disabled",
                          maxWidth: 120,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {work.divisionName}
                      </Typography>
                    </Stack>
                  </Box>

                  <Tooltip title="Çalışma Süresi" arrow>
                    <Chip
                      label={getTimeDiff(work.startedDate)}
                      size="small"
                      sx={{
                        height: 24,
                        backgroundColor: "rgba(239, 68, 68, 0.1)",
                        color: "error.main",
                        "& .MuiChip-label": {
                          px: 1,
                          fontSize: "0.75rem",
                          fontWeight: 500,
                        },
                        "& .MuiChip-icon": {
                          color: "error.main",
                        },
                      }}
                    />
                  </Tooltip>
                </Stack>
              </Box>
            ))}
          </Stack>
        </Box>
      </Box>
    </Card>
  );
}

// Ek stil için global CSS'e eklenecek
/*
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(203, 213, 225, 0.4) transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(203, 213, 225, 0.4);
  border-radius: 3px;
}
*/
