import { Toaster } from "react-hot-toast";
import { useRoutes } from "react-router-dom";
import router from "router";

function App() {
  const showRoutes = useRoutes(router);
  return (
    <>
      <Toaster position="top-right" />
      {showRoutes}
    </>
  );
}

export default App;
