import { Card } from "@mui/material";
import PreviewImage from "components/PreviewImage";
import { useSelector } from "react-redux";

export default function Header() {
  const user = useSelector((state) => state.authSlice.user);
  return ( 

    <div className="flex px-6 bg-white rounded-md py-1 m-3 justify-end items-center shadow-sm">
      <span className="font-medium pr-2">
        {user.name} {user.surname}
      </span>

      <PreviewImage
        className=" rounded-full h-12 w-12 p-1 border-2 border-gray-200"
        file={null}
        src={user.img}
      />
    </div>
  );
}
