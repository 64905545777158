import TableBody from "./Body";
import TableFoot from "./Foot";
import TableHead from "./Head"; 

export default function TableContent(getTableProps, headerGroups, footerGroups, getTableBodyProps, page, prepareRow, enableFilters) {
    return <div className="m-2 flex flex-col releative  overflow-y-hidden  ">
      <div className=" overflow-x-auto  ">
        <div className="py-1 w-full align-middle inline-block  ">
          <div className=" overflow-hidden borderb  border-gray-600  rounded">
            <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
              {TableHead(headerGroups, enableFilters)}
              {TableBody(getTableBodyProps, page, prepareRow)}
              {TableFoot(footerGroups)}
            </table>
          </div>
        </div>
      </div>
    </div>;
  }
  