import { TimelineConnector } from "@mui/lab";
import MuiTimeline from "@mui/lab/Timeline";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { styled } from "@mui/material/styles";
import noData from "asset/lottie/no-work";
import CustomLottie from "components/Lottie";
import { workTypeList } from "pages/working_hours/components/scheduler_functions";
import { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import WorkingHoursService from "services/working_hours_service";
import { AppUtils } from "utils/utils";

const Timeline = styled(MuiTimeline)({
  "& .MuiTimelineItem-root": {
    width: "100%",
    "&:before": {
      display: "none",
    },
    "& .MuiTimelineConnector-root": {
      backgroundColor: "#8F85F3",
    },
  },
});

export default function HomePageV3_WorkingHours() {
  const [selectedDay, setSelectedDay] = useState(
    AppUtils.dayOfWeek(new Date()) - 1
  );
  const [workingHours, setWorkingHours] = useState(null);
  useEffect(() => {
    const getAllWorkingHours = async () => {
      const data = await WorkingHoursService.getAll();
      setWorkingHours(
        data.sort(function (a, b) {
          return new Date(a.startDate) - new Date(b.startDate);
        })
      );
    };

    getAllWorkingHours();
  }, []);

  const changeDay = (isBack) => {
    if (isBack) {
      if (selectedDay == 0) return;
      setSelectedDay(selectedDay - 1);
    } else {
      if (selectedDay == 6) return;
      setSelectedDay(selectedDay + 1);
    }
  };
  const dotColor = ["success", "info", "error"];
  return (
    <div className="flex bg-white  rounded-md font-medium text-slate-500 overflow-auto h-[100%]">
      <div className="flex flex-col w-full ">
        <div className="flex justify-between select-none ">
          <div className=" text-sm text-slate-400"></div>
          <div className=" flex font-normal text-slate-400 items-center">
            <div className="font-medium text-sm text-primary px-2">
              {AppUtils.days[selectedDay]}
            </div>
            <IoIosArrowBack
              className="hover:bg-slate-100 rounded-full hover:cursor-pointer "
              onClick={() => changeDay(true)}
            />
            <div className="w-2" />
            <IoIosArrowForward
              className="hover:bg-slate-100 rounded-full hover:cursor-pointer "
              onClick={() => changeDay(false)}
            />
          </div>
        </div>
        {workingHours?.filter(
          (e) => selectedDay + 1 === AppUtils.dayOfWeek(e.startDate)
        ).length === 0 ? (
          <div className="flex flex-col w-full  items-center justify-center pt-16">
            <CustomLottie animationData={noData} size={200} />
            <div className="text-lg font-thin text-slate-400">
              Bugün çalışma yok
            </div>
          </div>
        ) : (
          <Timeline>
            {workingHours &&
              workingHours
                .filter(
                  (e) => selectedDay + 1 === AppUtils.dayOfWeek(e.startDate)
                )
                .map((v, index) => {
                  if (selectedDay + 1 === AppUtils.dayOfWeek(v.startDate)) {
                    return (
                      <TimelineItem>
                        <TimelineSeparator>
                          <TimelineDot
                            variant={v.isContinued ? "filled" : "outlined"}
                            color={dotColor[v.type]}
                          />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ pb: 0, pl: 1 }}>
                          <div className="flex flex-col gap-y-1 ">
                            <div className="flex ">
                              <div>
                                <div className="text-xs text-primary flex-col">
                                  <div className="flex text-xs text-primary p-1 border rounded-md">
                                    {AppUtils.formatClock(v.startDate)}
                                  </div>
                                </div>
                              </div>
                              <div className="flex">
                                <div className="flex text-xs p-1 text-primary600 font-medium">
                                  {v.text}
                                </div>
                              </div>
                            </div>

                            <div className="flex">
                              <div
                                className="flex text-white text-xs p-0.5 border rounded-md"
                                style={{
                                  backgroundColor: workTypeList[v.type].color,
                                  opacity: 0.7,
                                }}
                              >
                                x{v.coefficient}
                              </div>
                            </div>
                            {index ===
                              workingHours.filter(
                                (e) =>
                                  selectedDay + 1 ===
                                  AppUtils.dayOfWeek(e.startDate)
                              ).length -
                                1 && (
                              <div className="flex pt-2">
                                <div className="flex  w-min text-xs text-primary p-1 border rounded-md">
                                  {AppUtils.formatClock(v.endDate)}
                                </div>
                                <div className="flex text-xs p-1 text-primary600 font-medium">
                                  {"BİTİŞ"}
                                </div>
                              </div>
                            )}
                          </div>
                        </TimelineContent>
                      </TimelineItem>
                    );
                  }
                })}
          </Timeline>
        )}
      </div>
    </div>
  );
}
