import classNames from "classnames";
import { useState } from "react";
import { FiPlusSquare, FiMinusSquare } from "react-icons/fi";

export default function TableBody(getTableBodyProps, page, prepareRow) {
  const [selected, setSelected] = useState(null);
  return (
    <tbody {...getTableBodyProps()} className="bg-white divide-y p-5  ">
      {page.map((row) => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps()}>
            {row.cells.map((cell) => {
              return (
                <td
                  {...cell.getCellProps()}
                  className={classNames(
                    "  px-2 py-1 whitespace-nowrap text-sm  cursor-default ",
                    {
                      "bg-slate-50  ": cell.row.id % 2 !== 0,
                      "bg-blue-100  ": selected === cell.row.id,
                    }
                  )}
                  onClick={() =>
                    selected === cell.row.id
                      ? setSelected(null)
                      : setSelected(cell.row.id)
                  }
                >
                  {cell.isGrouped ? (
                    // If it's a grouped cell, add an expander and row count
                    <>
                      <div className="flex gap-x-1">
                      <span {...row.getToggleRowExpandedProps()}>
                        {row.isExpanded ? (
                          <FiMinusSquare className="h-4 w-4 text-orange-500 mr-2" />
                        ) : (
                          <FiPlusSquare className="h-4 w-4 text-orange-500  mr-2" />
                        )}
                      </span>
                      {cell.render("Cell")} ({row.subRows.length})
                      </div>
                    </>
                  ) : cell.isAggregated ? (
                    // If the cell is aggregated, use the Aggregated
                    // renderer for cell
                    cell.render("Aggregated")
                  ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                    // Otherwise, just render the regular cell
                    cell.render("Cell")
                  )}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
}
