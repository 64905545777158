import { CardContent } from "@material-ui/core";
import { Card, Typography } from "@mui/material";
import { ResponsiveCalendar } from "@nivo/calendar";
import CustomDialog from "components/dialog";
import WorkReportsTable from "pages/reports/tables/work_table";
import { use } from "react";
import { useEffect, useState } from "react";
import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";
import { AppUtils } from "utils/utils";

export default function ShiftTableCard({ currentYear }) {
  const [data, setData] = useState(null);

  const [calendarDate, setCalendarDate] = useState(currentYear);
  const [isOpenWorksDialog, setIsOpenWorksDialog] = useState(false);
  const [selectedWorks, setSelectedWorks] = useState(null);

  const {
    data: works,
    isLoading,
    error,
  } = useFetchData(`${ApiConstants.WORKS}${`?year=${currentYear}`}`);

  useEffect(() => {
    setCalendarDate(currentYear);
  }, [currentYear]);

  useEffect(() => {
    if (works) {
      var completedWorks = getCompletedWorks();
      var _calendarData = AppUtils.groupBy(
        completedWorks,
        "startedDateOnlyDate"
      );
      _calendarData = _calendarData.map((v) => {
        var _totalUsedTime = v.value
          .reduce((partialSum, a) => partialSum + a.usedTime, 0)
          .toFixed(0);
        return {
          value: _totalUsedTime,
          day: v.key,
        };
      });
      setData(_calendarData);
    }
  }, [works, calendarDate]);

  if (error) return <div>Error...</div>;

  const getCompletedWorks = () => {
    const _works = works.filter((a) => a.endedDate !== null);
    return _works.map((v) => {
      const startedDateOnlyDate = v.startedDate.slice(0, 10);
      const endedDateOnlyDate = v.endedDate.slice(0, 10);
      return { ...v, startedDateOnlyDate, endedDateOnlyDate };
    });
  };

  return (
    <>
      <CustomDialog
        isOpen={isOpenWorksDialog}
        setIsOpen={setIsOpenWorksDialog}
        title={""}
        maxWidth="max-w-7xl"
        body={<WorkReportsTable worksData={selectedWorks}></WorkReportsTable>}
      ></CustomDialog>
      <Card>
        <CardContent className="flex flex-col justify-between ">
          <div className="flex items-center  justify-between w-full px-1 ">
            <Typography
              variant="body2"
              className=" text-gray-600 font-semibold"
            >
              Mesai Tablosu
            </Typography>
            <div className="flex  justify-end">
              <Typography
                variant="caption"
                sx={{
                  px: 1,
                  py: 0.5,
                  borderRadius: 1,
                  backgroundColor: "primary.main",
                  color: "white",
                  fontWeight: 500,
                }}
              >
                {currentYear}
              </Typography>
            </div>
          </div>
          <div className="flex items-start h-[15vh]  justify-start w-full   ">
          <ResponsiveCalendar
                data={data || []}
                from={
                  calendarDate ? `${calendarDate}` : data[data.length - 1].day
                }
                to={calendarDate ? `${calendarDate}` : data[0].day}
                emptyColor="#f1f1f1"
                colors={["#61cdbb", "#44AC9BFF", "#FAC898", "#f47560"]}
                margin={{ right: 10, left: 20, top: 25, bottom: 0 }}
                monthBorderColor="#ffffff"
                dayBorderWidth={2}
                dayBorderColor="#ffffff"
                onClick={(e) => {
                  if (e.color === "#f1f1f1") {
                    return;
                  }

                  const selectedWorks = works.filter(
                    (a) => a.startedDate.slice(0, 10) === e.day
                  );

                  setSelectedWorks(selectedWorks);
                  setIsOpenWorksDialog(true);
                }}
                legends={[
                  {
                    anchor: "bottom-right",
                    direction: "row",
                    translateY: 36,
                    itemCount: 4,
                    itemWidth: 42,
                    itemHeight: 36,
                    itemsSpacing: 14,
                    itemDirection: "right-to-left",
                  },
                ]}
              />
          </div>
        </CardContent>
      </Card>
    </>
  );
}
