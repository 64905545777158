import { Icon } from "@iconify/react";
import { Avatar, Box, Card, Chip, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AppUtils } from "utils/utils";

export default function TopPerformersCard({ works }) {
  const [topUsers, setTopUsers] = useState([]);

  useEffect(() => {
    if (works) {
      // Kullanıcılara göre grupla
      const userGroups = AppUtils.groupBy(works, "user");

      // Her kullanıcının toplam çalışma süresini hesapla
      const userStats = userGroups.map((group) => ({
        user: group.key,
        userProfile: group.value[0].userProfile,
        totalTime: group.value.reduce(
          (sum, work) => sum + (work.usedTime || 0),
          0
        ),
        totalWorks: group.value.length,
      }));

      // En çok çalışma yapan 5 kullanıcıyı al
      const sortedUsers = userStats
        .sort((a, b) => b.totalTime - a.totalTime)
        .slice(0, 5);
      setTopUsers(sortedUsers);
    }
  }, [works]);

  const getRankBadgeColor = (index) => {
    switch (index) {
      case 0:
        return "#fbbf24"; // Gold
      case 1:
        return "#94a3b8"; // Silver
      case 2:
        return "#d97706"; // Bronze
      default:
        return "transparent";
    }
  };

  return (
    <Card
      sx={{
        height: "400px",
        background: "linear-gradient(to bottom, #f8fafc, #ffffff)",
      }}
    >
      <Box
        sx={{ p: 2, height: "100%", display: "flex", flexDirection: "column" }}
      >
        {/* Header */}
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1, mb: 2 }}>
          <Icon icon="mdi:trophy" color="#3f51b5" width="20" height="20" />
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle2" color="primary" fontWeight={600}>
              En Çok Çalışanlar
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: "text.secondary",
                display: "block",
                mt: 0.5,
              }}
            >
              En yüksek çalışma saatine sahip personellerin sıralaması
            </Typography>
          </Box>
          <Box
            sx={{
              px: 1,
              py: 0.5,
              borderRadius: 1,
              bgcolor: "primary.main",
              color: "white",
            }}
          >
            <Typography variant="caption" fontWeight={500}>
              Top 5
            </Typography>
          </Box>
        </Box>

        {/* Content */}
        <Box className="flex-1 overflow-auto custom-scrollbar">
          <Stack spacing={1.5}>
            {topUsers.map((user, index) => (
              <Box
                key={index}
                sx={{
                  p: 1.5,
                  borderRadius: 1,
                  backgroundColor: "white",
                  boxShadow: "0 1px 3px rgba(0,0,0,0.05)",
                  "&:hover": {
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    transform: "translateY(-1px)",
                    transition: "all 0.2s ease",
                  },
                }}
              >
                <Stack direction="row" alignItems="center" spacing={1.5}>
                  <Box sx={{ position: "relative" }}>
                    <Avatar
                      src={user.userProfile}
                      sx={{
                        width: 40,
                        height: 40,
                        border: "2px solid #fff",
                        boxShadow: "0 0 0 2px rgba(59, 130, 246, 0.1)",
                      }}
                    />
                    {index < 3 && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: -4,
                          right: -4,
                          width: 20,
                          height: 20,
                          borderRadius: "50%",
                          bgcolor: getRankBadgeColor(index),
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "0.75rem",
                          fontWeight: 600,
                          border: "2px solid white",
                        }}
                      >
                        {index + 1}
                      </Box>
                    )}
                  </Box>

                  <Box sx={{ flex: 1, minWidth: 0 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 600,
                        color: "text.primary",
                        lineHeight: 1.2,
                      }}
                    >
                      {user.user}
                    </Typography>

                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      sx={{ mt: 0.5 }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          color: "text.secondary",
                          display: "flex",
                          alignItems: "center",
                          gap: 0.5,
                        }}
                      >
                        <Icon icon="mdi:history" width="14" height="14" />
                        {Math.round(user.totalTime / 60)} saat
                      </Typography>

                      <Typography
                        variant="caption"
                        sx={{
                          color: "text.disabled",
                        }}
                      >
                        •
                      </Typography>

                      <Typography
                        variant="caption"
                        sx={{
                          color: "text.secondary",
                        }}
                      >
                        {user.totalWorks} iş
                      </Typography>
                    </Stack>
                  </Box>

                  <Chip
                    label={`${(user.totalTime / 60 / 8).toFixed(1)} gün`}
                    size="small"
                    sx={{
                      height: 24,
                      backgroundColor: "primary.main",
                      color: "white",
                      "& .MuiChip-label": {
                        px: 1,
                        fontSize: "0.75rem",
                        fontWeight: 500,
                      },
                    }}
                  />
                </Stack>
              </Box>
            ))}
          </Stack>
        </Box>
      </Box>
    </Card>
  );
}
