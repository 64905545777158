import { BsClipboardData } from "react-icons/bs";
import { FiChevronRight, FiHome, FiLogOut, FiSettings } from "react-icons/fi";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { NavLink } from "react-router-dom";

import { Disclosure } from "@headlessui/react";
import classNames from "classnames";
import CustomButton from "components/Form/Button";
import { TbLayoutDashboard } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { logout } from "store/auth";

export default function Sidebar() {
  const dispatch = useDispatch();
  return (
    <aside className="w-60 pt-6 flex flex-shrink-0 flex-col bg-[#ffffff] items-center shadow-xl ">
      <a href="/">
        <p className="text-4xl font-bold text-red-600 pb-6 ">
          Tedak <span className="text-base">admin</span>
        </p>
      </a>
      <nav className="w-full pt-2">
        <ul className="px-2 gap-y-1 flex flex-col">
          <li className="items-center  ">
            <NavLink to={"/admin/"} className={buildClassName()}>
              <FiHome />
              <span>Anasayfa</span>
            </NavLink>
          </li>
          <li className="items-center  ">
            <NavLink to={"/admin/dashboard"} className={buildClassName()}>
              <TbLayoutDashboard />
              <span>Dashboard</span>
            </NavLink>
          </li>
          <li className="items-center ">
            <NavLink to={"/admin/reports"} className={buildClassName()}>
              <HiOutlineDocumentReport />
              <span>Raporlar</span>
            </NavLink>
          </li>
          <li className="items-center ">
            <NavLink to={"/admin/processMining"} className={buildClassName()}>
              <BsClipboardData />
              <span>Süreç Analizi</span>
            </NavLink>
          </li>
          <li>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className={buildClassName(open)}>
                    <FiSettings />
                    <span>Ayarlar</span>

                    <FiChevronRight
                      className={`ml-auto ${open ? "transform rotate-90" : ""}`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className={"bg-white"}>
                    <NavLink
                      to={"/admin/settings/users"}
                      className={buildClassName(null, true)}
                    >
                      •<span>Personeller</span>
                    </NavLink>
                    <NavLink
                      to={"/admin/settings/panels"}
                      className={buildClassName(null, true)}
                    >
                      •<span>Panolar</span>
                    </NavLink>
                    <NavLink
                      to={"/admin/settings/projects"}
                      className={buildClassName(null, true)}
                    >
                      •<span>Projeler</span>
                    </NavLink>
                    <NavLink
                      to={"/admin/settings/divisions"}
                      className={buildClassName(null, true)}
                    >
                      •<span>Bölümler</span>
                    </NavLink>
                    <NavLink
                      to={"/admin/settings/companies"}
                      className={buildClassName(null, true)}
                    >
                      •<span>Müşteriler</span>
                    </NavLink>
                    <NavLink
                      to={"/admin/settings/working-hours"}
                      className={buildClassName(null, true)}
                    >
                      •<span>Mesailer</span>
                    </NavLink>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </li>
        </ul>
      </nav>
      <div className="h-full" />

      <div className="px-2 w-full flex flex-col text-sm justify-center items-center my-3">
        <div className="text-slate-600 ">version 0.1.0</div>
        <div className="text-slate-500 ">Tasarım Merkezi</div>
        <div className="text-slate-500 ">E-PANOSİS</div>
      </div>

      <div className="px-2 w-full">
        <CustomButton
          onClick={() => dispatch(logout())}
          className=" mb-2 hover:bg-primary400 p-2 w-full justify-center bg-primary text-white "
        >
          <FiLogOut />
          <span>Çıkış</span>
        </CustomButton>
      </div>
    </aside>
  );

  function buildClassName(open, item) {
    return (navData) =>
      classNames(
        "w-full flex px-2.5 font-medium gap-x-2 items-center py-1.5 rounded hover:text-slate-700 ",
        {
          "text-gray-700  hover:bg-primary30": !navData.isActive,
          "bg-primary30": navData.isActive,
          "text-white": open,
          "pl-4 mb-1  text-slate-500": item,
        }
      );
  }
}
