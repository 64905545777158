import { TabList, TabPanel } from "@mui/lab";
import { styled } from "@mui/material";

const CustomTabList = styled(TabList)`
  display: flex;

  .MuiTab-root {
    min-height: 30px;
    min-width: 80px;
    padding: 4px 4px;
    text-transform: none;
    font-size: 0.75rem;
    margin-right: 6px;
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out;
    color: #3c3c3cff;

    &:hover {
      border-radius: 6px;
      font-size: 0.85rem;
    }

    &.Mui-selected {
      background-color: #7367f0;
      color: #ffffff;
      border-radius: 6px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      &:hover {
        background-color:  #8176F2;
      }
    }
  }

  .MuiTabs-indicator {
    display: none;
  }
`;

const CustomTabPanel = styled(TabPanel)`
  padding: 0;
`;

export { CustomTabList, CustomTabPanel };
