import { Icon } from "@iconify/react";
import { Box, Card, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ReactApexCharts from "react-apexcharts";
import { AppUtils } from "utils/utils";

export default function WorkloadTrendCard({ works }) {
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "inherit",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "60%",
          borderRadius: 4,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 500,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: "#6b7280",
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        title: {
          text: "Saat",
          style: {
            color: "#6b7280",
            fontSize: "13px",
            fontWeight: 500,
          },
        },
        labels: {
          style: {
            colors: "#6b7280",
            fontSize: "12px",
          },
        },
      },
      grid: {
        borderColor: "#e5e7eb",
        strokeDashArray: 3,
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 10,
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (val) {
            return val + " saat";
          },
        },
        style: {
          fontSize: "12px",
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        offsetY: -8,
        markers: {
          width: 8,
          height: 8,
          radius: 4,
        },
        itemMargin: {
          horizontal: 12,
        },
        fontSize: "13px",
        fontWeight: 500,
      },
      colors: ["#6366f1", "#22c55e", "#eab308", "#ef4444"],
    },
  });

  useEffect(() => {
    if (works) {
      // Son 6 ayın verilerini al
      const last6Months = [...Array(6)]
        .map((_, i) => {
          const d = new Date();
          d.setMonth(d.getMonth() - i);
          return d.toISOString().slice(0, 7);
        })
        .reverse();

      // Bölümlere göre grupla
      const divisionGroups = AppUtils.groupBy(works, "divisionName");

      // Her bölüm için seriyi hazırla
      const series = divisionGroups.slice(0, 4).map((group) => ({
        name: group.key,
        data: last6Months.map((month) => {
          const monthWorks = group.value.filter((w) =>
            w.startedDate.startsWith(month)
          );
          return Math.round(
            monthWorks.reduce((sum, w) => sum + (w.usedTime || 0), 0) / 60
          );
        }),
      }));

      setChartData((prev) => ({
        ...prev,
        series,
        options: {
          ...prev.options,
          xaxis: {
            categories: last6Months.map((m) => {
              const [year, month] = m.split("-");
              return `${month}/${year}`;
            }),
          },
        },
      }));
    }
  }, [works]);

  return (
    <Card
      sx={{
        height: "400px",
        background: "linear-gradient(to bottom, #f8fafc, #ffffff)",
      }}
    >
      <Box
        sx={{ p: 2, height: "100%", display: "flex", flexDirection: "column" }}
      >
        {/* Header */}
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1, mb: 2 }}>
          <Icon icon="mdi:trending-up" color="#3f51b5" width="20" height="20" />
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle2" color="primary" fontWeight={600}>
              Bölüm Bazlı İş Yükü
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: "text.secondary",
                display: "block",
                mt: 0.5,
              }}
            >
              Bölümlerin aylık çalışma saatlerinin karşılaştırmalı analizi
            </Typography>
          </Box>
          <Box
            sx={{
              px: 1,
              py: 0.5,
              borderRadius: 1,
              bgcolor: "primary.main",
              color: "white",
            }}
          >
            <Typography variant="caption" fontWeight={500}>
              Son 6 Ay
            </Typography>
          </Box>
        </Box>

        {/* Chart */}
        <Box
          sx={{
            flex: 1,
            minHeight: 0,
            ".apexcharts-canvas": {
              background: "transparent !important",
            },
          }}
        >
          <ReactApexCharts
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height="100%"
            width="100%"
          />
        </Box>
      </Box>
    </Card>
  );
}
