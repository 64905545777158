import { LinearProgress } from "@mui/material";
import Table from "components/Table";
import { OverflowCell } from "components/Table/components/overflow";
import CustomToolTip from "components/tooltip";
import HomePageV2_DivisionChart from "pages/home/v2/division_chart";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

const MyLinearProgress = ({ value }) => {
  return (
    <LinearProgress
      value={value}
      variant="determinate"
      color={value > 90 ? "error" : value > 70 ? "warning" : "success"}
      className="min-bs-2   cursor-pointer"
      sx={{
        height: "16px",
        borderRadius: " 12px",
        "& .MuiLinearProgress-bar": {
          borderRadius: "12px",
        },
        "& .MuiLinearProgress-dashed": {
          marginTop: "0.2rem",
        },
      }}
    />
  );
};

function DashboardPanels() {
  const { panels } = useSelector((state) => state.dashboardSlice);
  useEffect(() => {}, [panels]);

  const columns = useMemo(() => {
    const col = [
      {
        Header: "Panel Adı",
        accessor: "panelName",
        canGroupBy: false,
      },
      {
        Header: "Panel Kodu",
        accessor: "panelCode",
        canGroupBy: false,
      },
      {
        Header: "Son Müşteri",
        accessor: "companyName",
        canGroupBy: false,
        Cell: OverflowCell,
      },
      {
        Header: "Tamamlanma Yüzdesi",
        accessor: "timePercent",
        Cell: ({ row }) => {
          console.log(row.original);
          const timePercent =
            (row.original?.totalBudget ?? 0) === 0
              ? 0
              : parseInt(
                  ((row.original.usedBudget ?? 0) /
                    (row.original?.totalBudget || 0)) *
                    100
                );

          // return <MyLinearProgress value={timePercent}  />;
          return (
            <CustomToolTip
              content={` ${row.original.usedBudget} / ${row.original.totalBudget} ₺`}
            >
              <div className="relative">
                <MyLinearProgress value={timePercent} color="#34d399" />
                <div
                  className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  text-xs text-white `}
                >
                  {timePercent + " %"}
                </div>
              </div>
            </CustomToolTip>
          );
        },
        canGroupBy: false,
      },
    ];
    return col;
  }, [panels]);

  const dotColor = ["success", "info", "error"];

  if (!panels) return null;

  console.log("**********************");
  console.log(panels);
  console.log("**********************");

  return (
    <div className="flex flex-col h-full w-full">
      <Table
        className="h-full relative w-full"
        columns={columns}
        data={panels}
        pageSize={50}
        exportExcel={false}
        fileName="Panolar"
        enableFilters={false}
        enablePageNumbers={false}
      />
      <HomePageV2_DivisionChart />
    </div>
  );
}

export default DashboardPanels;
