import dagre from "@dagrejs/dagre";
import { List, ListItem, ListItemText, Popover } from "@mui/material";
import CustomButton from "components/Form/Button";
import CustomDialog from "components/dialog";
import React, { useCallback, useEffect, useState } from "react";
import ReactFlow, {
  Background,
  ConnectionLineType,
  Controls,
  MiniMap,
  Panel,
  addEdge,
  useEdgesState,
  useNodesState,
} from "reactflow";
import WorkService from "services/works_service";
import uniqolor from "uniqolor";
import CustomEdge from "./customEdge";
import ProcessMiningWorksDialog from "./worksDialog";
const nodeColors = [];

const getRandomColor = (divisionId) => {
  const curr = nodeColors.find((e) => e.divisionId === divisionId);
  var foregroundColor = "#000000";
  var backgroundColor = "#ffffff";
  if (curr) {
    foregroundColor = curr.foregroundColor;
    backgroundColor = curr.backgroundColor;
    return { foregroundColor, backgroundColor };
  }
  foregroundColor = uniqolor(divisionId, {
    lightness: 40,
  }).color;
  backgroundColor = uniqolor(divisionId, {
    lightness: [85, 95],
  }).color;

  nodeColors.push({ divisionId, foregroundColor, backgroundColor });
  return { foregroundColor, backgroundColor };
};

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;

const getLayoutedElements = (nodes, edges, direction = "LR") => {
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? "left" : "top";
    node.sourcePosition = isHorizontal ? "right" : "bottom";

    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

function arraysEqual(a1, a2) {
  return JSON.stringify(a1) === JSON.stringify(a2);
}

function sublistExists(mainList, subList) {
  return mainList.toString().startsWith(subList.toString());
}

function isLastNode(works, list) {
  for (let index = 0; index < works.length; index++) {
    const element = works[index];
    if (arraysEqual(element.divisionPattern, list)) {
      if (element.divisions.length === list.length) {
        return true;
      }
    }
  }

  return false;
}

const getNodeDetail = (data, pattern) => {
  pattern = pattern.split("-");
  const works = data.filter((e) => sublistExists(e.divisionPattern, pattern));

  const divisionName = works[0].divisions.filter(
    (e) => e.divisionID === pattern[pattern.length - 1]
  )[0].divisionName;

  return { divisionName, works };
};

function patternMaxLength(pattern) {
  let max = 0;
  pattern.forEach((element) => {
    if (element.split("-").length > max) {
      max = element.split("-").length;
    }
  });
  return max;
}
const edgeTypes = {
  custom: CustomEdge,
};

const setupFlow = (data) => {
  const patterns = [];

  data.forEach((element) => {
    for (let index = 0; index < element.divisionPattern.length; index++) {
      const pattern = element.divisionPattern.slice(0, index + 1);
      if (pattern.length === 0) {
        continue;
      }
      if (pattern.length === 1) {
        if (!patterns.includes(pattern[0])) {
          patterns.push(pattern[0]);
        }
      } else {
        let patternStr = "";
        pattern.forEach((element) => {
          patternStr === ""
            ? (patternStr = element)
            : (patternStr = patternStr + "-" + element);
        });
        if (!patterns.includes(patternStr)) {
          patterns.push(patternStr);
        }
      }
    }
  });
  const patternMax = patternMaxLength(patterns);
  const max = patternMax * 150;
  let nodes = [
    {
      id: "0",
      position: { x: 0, y: 0 },
      data: { label: "Başlangıç" },
      targetPosition: "left",
      sourcePosition: "right",
      style: {
        background: "#A3EBB1",
        color: "#116530",
        border: "1px solid #116530",
      },
    },
    // {
    //   id: "1",
    //   position: { x: max, y: 0 },
    //   data: { label: "Bitiş" },
    //   targetPosition: "left",
    //   sourcePosition: "right",
    // },
  ];

  let edges = [];

  patterns.forEach((pattern) => {
    const elementList = pattern.split("-");
    const lastNode = isLastNode(data, elementList);
    const { divisionName, works } = getNodeDetail(data, pattern);
    const { foregroundColor, backgroundColor } = getRandomColor(
      elementList[elementList.length - 1]
    );
    if (elementList.length === 1) {
      nodes.push({
        id: pattern,
        position: { x: elementList.length * 150, y: 0 },
        data: { label: divisionName, works },
        targetPosition: "left",
        sourcePosition: "right",
        style: {
          background: backgroundColor,
          color: foregroundColor,
          border: "1px solid " + foregroundColor,
        },
      });
      edges.push({
        id: "0/" + pattern,
        source: "0",
        target: pattern,
        animated: true,
        direction: "top",
        type: "custom",
        data: { works },
      });
    } else {
      let source = elementList
        .slice(0, elementList.length - 1)
        .reduce((a, b) => a + "-" + b);
      nodes.push({
        id: pattern,
        position: { x: elementList.length * 150, y: 0 },
        data: { label: divisionName, works },
        targetPosition: "left",
        sourcePosition: "right",
        style: {
          background: backgroundColor,
          color: foregroundColor,
          border: "1px solid " + foregroundColor,
        },
      });
      edges.push({
        id: source + "/" + pattern,
        source: source,
        target: pattern,
        animated: true,
        direction: "top",
        data: { works },
        type: "custom",
      });
    }
    if (lastNode) {
      // edges.push({
      //   id: pattern + "/1",
      //   source: pattern,
      //   target: "1",
      //   animated: true,
      //   type: "smoothstep",
      // });
    }
  });

  return { nodes, edges };
};

const initialNodes = [
  {
    id: "0",
    position: { x: 0, y: 0 },
    data: { label: "Başlangıç" },
    targetPosition: "left",
    sourcePosition: "right",
  },
];
const initialEdges = [];

const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
  initialNodes,
  initialEdges
);

function ProcessMining() {
  const [process, setProcess] = useState([]);
  const [selectedEdgeData, setSelectedEdgeData] = useState([]);
  const [isOpenWorksDialog, setIsOpenWorksDialog] = useState(false);

  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  useEffect(() => {
    const fetchData = async () => {
      const res = await WorkService.getWorksForMining();
      setProcess(res);
      const { nodes, edges } = setupFlow(res);
      setNodes(nodes);
      setEdges(edges);
    };
    fetchData();
  }, []);

  const onConnect = useCallback((params) => {
    setEdges((eds) =>
      addEdge(
        { ...params, type: ConnectionLineType.SmoothStep, animated: true },
        eds
      )
    );
  }, []);

  useEffect(() => {}, [selectedEdgeData]);

  const onLayout = useCallback(
    (direction = "LR") => {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(nodes, edges, direction);

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
    },
    [nodes, edges]
  );

  const [anchorNodePopupEl, setAnchorNodePopupEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorNodePopupEl(event.currentTarget);
  };

  const [node, setNode] = useState(null);

  const handleClose = () => {
    setAnchorNodePopupEl(null);
  };

  const open = Boolean(anchorNodePopupEl);

  return (
    <div style={{ width: "100%", height: "90vh" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        style={{
          pointerEvents: "all",
        }}
        connectionLineType={ConnectionLineType.SmoothStep}
        onNodeDoubleClick={(event, node) => {
          setNode(node);
          handleClick(event);
        }}
        onEdgeDoubleClick={(event, edge) => {
          setSelectedEdgeData(edge.data);
          setIsOpenWorksDialog(true);
        }}
        edgeTypes={edgeTypes}
        fitView
      >
        <Controls />
        <Background variant="dots" gap={12} size={1} />
        <MiniMap />

        <Panel position="top-right" className="flex">
          <CustomButton onClick={() => onLayout("LR")}>
            Yatay görünüm
          </CustomButton>
        </Panel>
      </ReactFlow>
      <CustomDialog
        isOpen={isOpenWorksDialog}
        setIsOpen={setIsOpenWorksDialog}
        title={""}
        maxWidth=" max-w-7xl"
        body={ProcessMiningWorksDialog({ edgeData: selectedEdgeData })}
      ></CustomDialog>

      <Popover
        id={"ids"}
        open={open}
        anchorEl={anchorNodePopupEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List dense>
          {node &&
            node.data.works.map((e) => (
              <ListItem>
                <ListItemText primary={e.panelName} />
              </ListItem>
            ))}
        </List>
      </Popover>
    </div>
  );
}

function ProcessMiningPage(params) {
  return <ProcessMining />;
}

export default ProcessMiningPage;
