import ApiConstants from "./api";
import { fetchData, putData } from "./axios_helper";
class WorkService {
  static async completeAllWork({query}) {
    const res = await fetchData(ApiConstants.completeAllWork + query);
    return res;
  }
  static getAll = ({ query }) => fetchData(ApiConstants.WORKS + query);

  static async getWorksForMining() {
    const res = await fetchData(ApiConstants.getWorksForMining);
    return res;
  }

  static async updateWork({ id, data }) {
    const res = await putData(ApiConstants.WORKS + "/" + id, data);

    return res;
  }
}

export default WorkService;
