import { Icon } from "@iconify/react";
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ReactApexCharts from "react-apexcharts";
import ApiConstants from "services/api";
import { useFetchData } from "services/axios_helper";

export default function ProjectsOverviewCard({ works, currentYear }) {
  const { data: panels } = useFetchData(ApiConstants.PANELS);

  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: "area",
        height: 280,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        fontFamily: "inherit",
      },
      colors: ["#6366f1", "#22c55e"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
        lineCap: "round",
      },
      grid: {
        strokeDashArray: 3,
        borderColor: "#e5e7eb",
      },
      xaxis: {
        type: "datetime",
        labels: {
          format: "MM/yy",
          style: {
            colors: "#6b7280",
            fontSize: "12px",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: [
        {
          title: {
            text: "Çalışma Saati",
            style: {
              color: "#6366f1",
              fontSize: "13px",
              fontWeight: 500,
            },
          },
          labels: {
            style: {
              colors: "#6b7280",
              fontSize: "12px",
            },
          },
        },
        {
          opposite: true,
          title: {
            text: "Bütçe (₺)",
            style: {
              color: "#22c55e",
              fontSize: "13px",
              fontWeight: 500,
            },
          },
          labels: {
            style: {
              colors: "#6b7280",
              fontSize: "12px",
            },
          },
        },
      ],
      tooltip: {
        shared: true,
        x: {
          format: "dd MMM yyyy",
        },
        style: {
          fontSize: "12px",
        },
        theme: "light",
        y: [
          {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0) + " saat";
              }
              return y;
            },
          },
          {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return "₺" + y.toLocaleString("tr-TR");
              }
              return y;
            },
          },
        ],
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.45,
          opacityTo: 0.05,
          stops: [50, 100, 100],
        },
      },
    },
  });

  useEffect(() => {
    if (works) {
      // Son 6 ayın verilerini al
      const last6Months = [...Array(6)]
        .map((_, i) => {
          const d = new Date();
          d.setMonth(d.getMonth() - i);
          return d.toISOString().slice(0, 7);
        })
        .reverse();

      const monthlyData = last6Months.map((month) => {
        const monthWorks = works.filter((w) => w.startedDate.startsWith(month));
        return {
          x: new Date(month).getTime(),
          totalTime: Math.round(
            monthWorks.reduce((sum, w) => sum + (w.usedTime || 0), 0) / 60
          ),
          totalBudget: Math.round(
            monthWorks.reduce((sum, w) => sum + (w.usedBudget || 0), 0)
          ),
        };
      });

      setChartData((prev) => ({
        ...prev,
        series: [
          {
            name: "Çalışma Saati",
            data: monthlyData.map((d) => ({ x: d.x, y: d.totalTime })),
          },
          {
            name: "Kullanılan Bütçe",
            data: monthlyData.map((d) => ({ x: d.x, y: d.totalBudget })),
          },
        ],
      }));
    }
  }, [works]);

  // Panel durumlarını hesapla
  const getPanelStats = () => {
    if (!panels) return { active: 0, completed: 0, pending: 0 };

    return panels.reduce(
      (acc, panel) => {
        if (panel.state === "Devam ediyor") acc.active++;
        else if (panel.state === "Tamamlandı") acc.completed++;
        else if (panel.state === "Askıda") acc.pending++;
        return acc;
      },
      { active: 0, completed: 0, pending: 0 }
    );
  };

  const panelStats = getPanelStats();

  return (
    <Card
      sx={{
        height: "400px",
        background: "linear-gradient(to bottom, #f8fafc, #ffffff)",
      }}
    >
      <Box
        sx={{ p: 2, height: "100%", display: "flex", flexDirection: "column" }}
      >
        {/* Header */}
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1, mb: 2 }}>
          <Icon icon="mdi:clipboard-text" color="#3f51b5" width="20" height="20" />
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle2" color="primary" fontWeight={600}>
              Proje Genel Bakış
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: "text.secondary",
                display: "block",
                mt: 0.5,
              }}
            >
              Son 6 aylık çalışma saatleri ve bütçe kullanımının detaylı analizi
            </Typography>
          </Box>
          <Typography
            variant="caption"
            sx={{
              px: 1,
              py: 0.5,
              borderRadius: 1,
              backgroundColor: "primary.main",
              color: "white",
              fontWeight: 500,
            }}
          >
            {currentYear}
          </Typography>
        </Box>

        {/* Stats */}
        <Grid container spacing={1.5} sx={{ mb: 3 }}>
          <Grid item xs={4}>
            <Box
              sx={{
                p: 1.5,
                borderRadius: 2,
                bgcolor: "rgba(99, 102, 241, 0.08)",
                display: "flex",
                flexDirection: "column",
                gap: 1,
                height: "100%",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Icon icon="mdi:autorenew" color="#3f51b5" width="20" height="20" />
                <Stack spacing={0}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "primary.main",
                      fontWeight: 700,
                      lineHeight: 1,
                    }}
                  >
                    {panelStats.active}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ color: "text.secondary", fontWeight: 500 }}
                  >
                    Aktif
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                p: 1.5,
                borderRadius: 2,
                bgcolor: "rgba(34, 197, 94, 0.08)",
                display: "flex",
                flexDirection: "column",
                gap: 1,
                height: "100%",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Icon icon="mdi:check-circle" color="#22c55e" width="20" height="20" />
                <Stack spacing={0}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "success.main",
                      fontWeight: 700,
                      lineHeight: 1,
                    }}
                  >
                    {panelStats.completed}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ color: "text.secondary", fontWeight: 500 }}
                  >
                    Tamamlanan
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                p: 1.5,
                borderRadius: 2,
                bgcolor: "rgba(234, 179, 8, 0.08)",
                display: "flex",
                flexDirection: "column",
                gap: 1,
                height: "100%",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Icon icon="mdi:pause-circle" color="#eab308" width="20" height="20" />
                <Stack spacing={0}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "warning.main",
                      fontWeight: 700,
                      lineHeight: 1,
                    }}
                  >
                    {panelStats.pending}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ color: "text.secondary", fontWeight: 500 }}
                  >
                    Beklemede
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Chart */}
        <Box
          sx={{
            flex: 1,
            minHeight: 0,
            ".apexcharts-canvas": {
              background: "transparent !important",
            },
          }}
        >
          <ReactApexCharts
            options={chartData.options}
            series={chartData.series}
            type="area"
            height="100%"
            width="100%"
          />
        </Box>
      </Box>
    </Card>
  );
}
